import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Course, CourseFile, deleteCourse, getCourse, loadCourseFiles, updateCourse } from "../services/courses";
import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import { capitalize } from "../utils/utils";
import { v4 as uuidv4 } from 'uuid';

export const useCourse = () => {
    const navigate = useNavigate();
    const { courseID } = useParams();

    const [savedCourse, setSavedCourse] = useState<Course | undefined>(undefined);
    const [tempCourse, setTempCourse] = useState<Course | undefined>(undefined);
    const [courseFiles, setCourseFiles] = useState<CourseFile[]>([]);
    const [courseSavingLoading, setCourseSavingLoading] = useState(false);

    const user = getUserDataFromLocalStorage();
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const latestTimerRef = useRef<Date | null>(null);

    const [hasChanges, setHasChanges] = useState(false);

    const sortObject = (obj: any): any => {
        if (Array.isArray(obj)) {
            return obj.map(sortObject).sort();
        } else if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).sort().reduce((result, key) => {
                result[key] = sortObject(obj[key]);
                return result;
            }, {} as any);
        }
        return obj;
    };

    const objectHash = async (obj: any): Promise<string> => {
        const sortedObj = sortObject(obj);
        const jsonString = JSON.stringify(sortedObj);
        const encoder = new TextEncoder();
        const data = encoder.encode(jsonString);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    };

    useEffect(() => {
        const compareObjects = async () => {
            if (tempCourse && savedCourse) {
                const tempHash = await objectHash(tempCourse);
                const savedHash = await objectHash(savedCourse);
                const changesDetected = tempHash !== savedHash;
                setHasChanges(changesDetected);
            } else {
                setHasChanges(false);
            }
        };

        compareObjects();
    }, [tempCourse, savedCourse]);

    useEffect(() => {
        loadCourseFiles(courseID!).then(() => { // Load course files first
            return getCourse(courseID!); // Ensure getCourse runs after files are loaded
        }).then(s => {
            const parsedCourse = JSON.parse(JSON.stringify(s));
            // Add unique UI keys to templates
            if (parsedCourse.templates) {
                parsedCourse.templates = parsedCourse.templates.map((template: any, index: number) => ({
                    ...template,
                    ui_key: uuidv4()
                }));
            }
            setSavedCourse(parsedCourse);
            setTempCourse(structuredClone(parsedCourse));
        });
    }, [courseID]);

    async function saveCourseChanges(): Promise<void> {
        if (tempCourse && hasChanges) {
            setCourseSavingLoading(true);
            try {
                await updateCourse({ ...tempCourse });
                message.success(`${capitalize(user.config.course_terminology)} updated successfully!`);
                setSavedCourse(structuredClone(tempCourse));
                setHasChanges(false);  // Reset hasChanges after successful save
                return Promise.resolve();  // Explicitly resolve the promise
            } catch (err) {
                message.error(`Error updating ${user.config.course_terminology.toLowerCase()}`);
                return Promise.reject(err);  // Reject the promise if there's an error
            } finally {
                setCourseSavingLoading(false);
            }
        } else {
            return Promise.resolve();  // If no changes, resolve immediately
        }
    }

    async function deleteCurrentCourse() {
        deleteCourse(courseID!).then(() => {
            message.success(`${capitalize(user.config.course_terminology)} deleted successfully!`)
        }).finally(() => {
            navigate("/courses");

        })
    }

    async function fetchCourseFiles(courseId: string) {
        try {
            const courseFiles = await loadCourseFiles(courseId);
            setCourseFiles(courseFiles);
        } catch (error) {
            console.error(`Error fetching ${user.config.course_terminology.toLowerCase()} files:`, error);
        }
    }

    async function checkFileStatusAfterUpload(uploadTimer: Date) {
        // Fetch course files
        await fetchCourseFiles(courseID!);

        const intervalId = setInterval(async () => {
            const currentFiles = await loadCourseFiles(courseID!);
            if (JSON.stringify(currentFiles) !== JSON.stringify(courseFiles)) {
                setCourseFiles(currentFiles);
            }

            // if uploadTimer is set and current time is greater than uploadTimer
            if (uploadTimer && Date.now() > uploadTimer.getTime()) {
                clearInterval(intervalId);
            }

        }, 5000); // Check every 5 second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }

    async function reloadSummaryPeriodically(uploadTimer: Date, weekIndex: number) {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        if (!latestTimerRef.current || uploadTimer > latestTimerRef.current) {
            latestTimerRef.current = uploadTimer;
        }

        const checkAndUpdateCourse = async () => {
            try {
                const newCourse = await getCourse(courseID!);
                // todo this should just check the summary
                if (tempCourse &&
                    newCourse.weeks[weekIndex].summary !== "Summarizing... (Remove this text to try again)" &&
                    newCourse.weeks[weekIndex].summary.length !== tempCourse.weeks[weekIndex].summary.length) {
                    // Update course if no user made changes present
                    if (!hasChanges) {
                        setSavedCourse(newCourse);
                    }
                    // update summary in tempCourse 
                    setTempCourse({
                        ...tempCourse,
                        weeks: tempCourse.weeks.map((week, index) =>
                            index === weekIndex ? { ...week, summary: newCourse.weeks[weekIndex].summary } : week
                        ),
                        last_updated: newCourse.last_updated
                    });
                    clearInterval(timerRef.current!);
                    timerRef.current = null;
                    latestTimerRef.current = null;
                }

                if (latestTimerRef.current && Date.now() > latestTimerRef.current.getTime()) {
                    clearInterval(timerRef.current!);
                    timerRef.current = null;
                    latestTimerRef.current = null;
                }
            } catch (error) {
                console.error("Error reloading course:", error);
                clearInterval(timerRef.current!);
                timerRef.current = null;
                latestTimerRef.current = null;
            }
        };

        timerRef.current = setInterval(checkAndUpdateCourse, 5000); // Check every 5 seconds

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }

    return {
        tempCourse,
        setTempCourse,
        saveCourseChanges,
        deleteCurrentCourse,
        courseSavingLoading,
        hasChanges,
        courseFiles,
        setCourseFiles,
        fetchCourseFiles,
        checkFileStatusAfterUpload,
        reloadSummaryPeriodically: reloadSummaryPeriodically
    };
}