import { useState, useEffect } from 'react';
import { Content } from 'antd/es/layout/layout';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { Typography, Table, Checkbox, Divider, Switch, Upload, Image, message, Row, Col, Spin } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import { Course, getUserCourses } from '../../services/courses';
import { getTotalStudentUsage, update_user_config } from '../../services/user';
import { SaveButton } from "../../components/basic/buttons";
import { PlusOutlined } from '@ant-design/icons';
import { capitalize } from '../../utils/utils';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';

const { Column } = Table;
const { Paragraph } = Typography;

const Administration = () => {
    const user = getUserDataFromLocalStorage();
    const { setHasUnsavedChanges } = useUnsavedChanges();

    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState<Course[]>([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
    const [savingLoading, setSavingLoading] = useState<boolean>(false);

    const [autoEnrollCourseIds, setAutoEnrollCourseIds] = useState<string[]>([]);
    const [autoEnrollAll, setAutoEnrollAll] = useState<boolean>(false);
    const [userOrganization, setUserOrganization] = useState<string>(user.config.organization);

    const [userMessageCap, setUserMessageCap] = useState<number>(user.config.user_message_cap as number);
    const [totalMessagesSent, setTotalMessagesSent] = useState<number>(0);
    const [userNumberCap, setUserNumberCap] = useState<number>(user.config.user_number_cap as number);
    const [orgMessageCap, setOrgMessageCap] = useState<number>(user.config.org_message_cap as number);

    const [courseTerminology, setCourseTerminology] = useState<string>(user.config.course_terminology);
    const [moduleTerminology, setModuleTerminology] = useState<string>(user.config.module_terminology);

    const [sourcesVisible, setSourcesVisible] = useState<boolean>(true);
    const [filterBySource, setFilterBySource] = useState<boolean>(true);
    const [filterByGroup, setFilterByGroup] = useState<boolean>(true);

    useEffect(() => {
        setHasUnsavedChanges(hasChanges);
    }, [hasChanges, setHasUnsavedChanges]);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedCourses = await getUserCourses(user!._id, user!.course_id_list);
            setCourses(fetchedCourses);

            if (user.config.auto_enroll_students_to_courses.includes('all')) {
                setAutoEnrollCourseIds(user.course_id_list);
                setAutoEnrollAll(true);
            } else {
                setAutoEnrollCourseIds(user.config.auto_enroll_students_to_courses);
            }

            setUserOrganization(user.config.organization);

            setUserNumberCap(user.config.user_number_cap as number);
            await getTotalStudentUsage(user._id, user.assigned_student_id_list).then((result) => {
                setTotalMessagesSent(result.total_student_usage as number + user.usage.current_sent_messages as number);
            });
            setUserMessageCap(user.config.user_message_cap as number);
            setOrgMessageCap(user.config.org_message_cap as number);

            setCourseTerminology(user.config.course_terminology);
            setModuleTerminology(user.config.module_terminology);

            setSourcesVisible(user.config.sources_visible);
            setFilterBySource(user.config.filter_by_source);
            setFilterByGroup(user.config.filter_by_group);

            setIsLoading(false);
        };

        if (!courses.length) {
            fetchData();
            if (user.config.logo) {
                const file: UploadFile = {
                    uid: '-1',
                    name: 'user_logo.png',
                    status: 'done',
                    url: user.config.logo,
                };
                setFileList([file]);
            } else {
                setFileList([]);
            }
        }
    }, [user._id]);

    useEffect(() => {
        setHasChanges(
            userOrganization !== user.config.organization
            || userMessageCap !== user.config.user_message_cap
            || userNumberCap !== user.config.user_number_cap
            || orgMessageCap !== user.config.org_message_cap
            || courseTerminology !== user.config.course_terminology
            || moduleTerminology !== user.config.module_terminology
        );
    }, [userOrganization, userMessageCap, userNumberCap, orgMessageCap, courseTerminology, moduleTerminology]);

    const uploadProps: UploadProps = {
        action: `${process.env.REACT_APP_BACKEND_URL}/users/upload-logo`,
        headers: {
            userid: user?._id || '',
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        fileList: fileList,
        name: 'logo',
        listType: "picture-circle",
        beforeUpload(file: UploadFile) {
            if (file.type !== 'image/png') {
                message.error('You can only upload PNG file!');
                return false;
            }
            if (file.size && file.size > 1000000) {
                message.error('Image must smaller than 1MB!');
                return false;
            }
            setFileList([file]);
        },
        onChange: async (info: any) => {
            if (info.file.status === 'done') {
                try {
                    const base64String = info.file.response.logo;
                    user.config.logo = base64String;
                    setUserDataInLocalStorage(user);
                    const file: UploadFile = {
                        uid: '-1',
                        name: 'user_logo.png',
                        status: 'done',
                        url: base64String,
                    };
                    setFileList([file]);
                    message.success('Logo uploaded successfully. Please refresh the page to see the changes.');
                } catch (error) {
                    console.error('Error processing uploaded file:', error);
                    message.error('Error processing uploaded file');
                }
            }
        },
        multiple: false
    };

    const handlePreview = async (file: UploadFile) => {
        // file.url = "data:image/png;base64, " + user.config.logo;
        file.preview = user.config.logo.split('base64,')[1];
        setPreviewOpen(true);
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const onAutoEnrollChange = async (courseId: string, checked: boolean) => {
        const newAutoEnrollCourseIds = checked
            ? [...autoEnrollCourseIds, courseId]
            : autoEnrollCourseIds.filter((id) => id !== courseId);
        setAutoEnrollCourseIds(newAutoEnrollCourseIds);
        setHasChanges((prevHasChanges) =>
            JSON.stringify(newAutoEnrollCourseIds.sort()) !== JSON.stringify(user.config.auto_enroll_students_to_courses.sort())
        );
    };

    const onAutoEnrollAllCurrentChange = async (checked: boolean) => {
        const newAutoEnrollCourseIds = checked ? courses.map((course) => course._id) : [];
        setAutoEnrollCourseIds(newAutoEnrollCourseIds);
        setHasChanges((prevHasChanges) =>
            JSON.stringify(newAutoEnrollCourseIds.sort()) !== JSON.stringify(user.config.auto_enroll_students_to_courses.sort())
        );
    };

    const onAutoEnrollAllChange = async (checked: boolean) => {
        setAutoEnrollAll(checked);
        setHasChanges(JSON.stringify(user.config.auto_enroll_students_to_courses.sort()) !== (
            checked ? JSON.stringify(['all']) : JSON.stringify(autoEnrollCourseIds.sort()))
        );
    };

    const onSourceVisibilityChange = async (checked: boolean) => {
        setSourcesVisible(checked);
        if (!checked) {
            setFilterBySource(false);
        }
        setHasChanges(checked !== user.config.sources_visible);
    };

    const onFilterBySourceChange = async (checked: boolean) => {
        setFilterBySource(checked);
        setHasChanges(checked !== user.config.filter_by_source);
    };

    const onFilterByGroupChange = async (checked: boolean) => {
        setFilterByGroup(checked);
        setHasChanges(checked !== user.config.filter_by_group);
    };

    async function saveChanges() {
        setSavingLoading(true);
        let tempUserAutoEnrollAttribute = autoEnrollAll ? ['all'] : autoEnrollCourseIds;
        await update_user_config(
            user._id,
            tempUserAutoEnrollAttribute,
            userOrganization,
            userNumberCap,
            userMessageCap,
            orgMessageCap,
            courseTerminology,
            moduleTerminology,
            sourcesVisible,
            filterBySource,
            filterByGroup
        ).then((result) => {
            if (result) {
                setUserDataInLocalStorage({
                    ...user, config:
                    {
                        ...user.config,
                        auto_enroll_students_to_courses: tempUserAutoEnrollAttribute,
                        organization: userOrganization,
                        user_message_cap: userMessageCap,
                        user_number_cap: userNumberCap,
                        org_message_cap: orgMessageCap,
                        course_terminology: courseTerminology,
                        module_terminology: moduleTerminology,
                        sources_visible: sourcesVisible,
                        filter_by_source: filterBySource,
                        filter_by_group: filterByGroup
                    }
                });
            }
        });
        setSavingLoading(false);
        setHasChanges(false);
    };

    return (
        <Content style={{ padding: "2rem" }}>
            {isLoading
                ? <Spin size="large" />
                : <>
                    <Typography.Title
                        level={1}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                        Administration
                    </Typography.Title>
                    {hasChanges && <SaveButton loading={savingLoading} type="primary" size="large" onClick={saveChanges} >Save Changes</SaveButton>}
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Name: </Paragraph>
                        </Col>
                        <Col span={20}>
                            <Paragraph >{user.name}</Paragraph>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Organization:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Paragraph editable={{ onChange: setUserOrganization }}>{userOrganization}</Paragraph>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph>Logo:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Upload {...uploadProps} onPreview={handlePreview}
                            >
                                {fileList!.length >= 8 ? null : uploadButton}
                            </Upload>
                        </Col>
                    </Row>

                    {fileList.length > 0 && (
                        <Image
                            wrapperStyle={{ display: 'none' }}
                            preview={{
                                visible: previewOpen,
                                onVisibleChange: (visible) => setPreviewOpen(visible),
                            }}
                            src={fileList[0]?.url}
                        />
                    )}
                    <Divider />
                    <Typography.Title
                        level={1}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                        {`${capitalize(user.config.course_terminology)}s`}
                    </Typography.Title>
                    <Paragraph>
                        Auto-enroll to all courses, current and future
                        <Switch
                            data-testid={`auto-enroll-all`}
                            checked={autoEnrollAll}
                            onChange={onAutoEnrollAllChange}
                            style={{ marginLeft: '0.5rem' }}
                        />
                    </Paragraph>
                    <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                        <Table
                            dataSource={courses}
                            rowKey="_id"
                            pagination={false}
                            scroll={{ y: '75vh' }}
                        >
                            <Column title={`${user.config.course_terminology} Name`} dataIndex="name" key="name" width={'20%'} />
                            <Column
                                title={
                                    <Checkbox
                                        checked={autoEnrollCourseIds.length === user.course_id_list.length}
                                        onChange={(e) => onAutoEnrollAllCurrentChange(e.target.checked)}
                                        disabled={autoEnrollAll}
                                    >
                                        Auto Enrollment
                                    </Checkbox>
                                }
                                key="autoEnroll"
                                render={(_, course_item: Course) => (
                                    <Checkbox
                                        key={`auto-enroll-${course_item._id}`}
                                        checked={autoEnrollCourseIds.includes(course_item._id)}
                                        onChange={(e) => onAutoEnrollChange(course_item._id, e.target.checked)}
                                        disabled={autoEnrollAll}
                                    />
                                )}
                                width={'15%'}
                            />
                            <Column title="Description" dataIndex="description" key="description" />
                        </Table>
                    </div>

                    <Divider />
                    <Typography.Title
                        level={1}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                        Caps
                    </Typography.Title>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>User number:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Paragraph >{user.assigned_student_id_list.length}</Paragraph>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>User number cap:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Paragraph editable={{ onChange: (value: string) => setUserNumberCap(Number(value)) }}>{userNumberCap}</Paragraph>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Messages sent:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Paragraph >{totalMessagesSent}</Paragraph>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Message cap per user:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Paragraph editable={{ onChange: (value: string) => setUserMessageCap(Number(value)) }}>{userMessageCap}</Paragraph>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Message cap for the organization:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Paragraph editable={{ onChange: (value: string) => setOrgMessageCap(Number(value)) }}>{orgMessageCap}</Paragraph>
                        </Col>
                    </Row>

                    <Divider />
                    <Typography.Title
                        level={1}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                        Terminology
                    </Typography.Title>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Rename {user.config.course_terminology} to:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Paragraph editable={{ onChange: setCourseTerminology }}>{courseTerminology}</Paragraph>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Rename {user.config.module_terminology} to:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Paragraph editable={{ onChange: setModuleTerminology }}>{moduleTerminology}</Paragraph>
                        </Col>
                    </Row>

                    <Divider />
                    <Typography.Title
                        level={1}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                        Settings
                    </Typography.Title>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Source visibility:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Switch checked={sourcesVisible} onChange={onSourceVisibilityChange} style={{ marginLeft: '0.5rem' }} />
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Students can filter by source filename:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Switch checked={filterBySource} disabled={!sourcesVisible} onChange={onFilterBySourceChange} style={{ marginLeft: '0.5rem' }} />
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={4}>
                            <Paragraph strong>Students can filter by group:</Paragraph>
                        </Col>
                        <Col span={20}>
                            <Switch checked={filterByGroup} onChange={onFilterByGroupChange} style={{ marginLeft: '0.5rem' }} />
                        </Col>
                    </Row>
                </>}
        </Content>
    );
};

export default Administration;