import React, { useState, useEffect } from 'react';
import { Content } from 'antd/es/layout/layout';
import { Typography, Table, Button, message, Spin } from 'antd';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { fetchAssignedStudents, removeSelectedStudents } from '../../services/user';

interface Student {
  _id: string;
  name: string;
  email: string;
  last_updated: string;
}

const AssignedStudents: React.FC = () => {
  const user = getUserDataFromLocalStorage();
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    await fetchAssignedStudents(
      user._id,
      user.assigned_student_id_list
    ).then((result) => {
      setStudents(result.assigned_students);
      setLoading(false);
    })
  };

  const removeStudents = async (studentIds: string[]) => {
    setLoading(true);
    await removeSelectedStudents(user._id, user.assigned_student_id_list, studentIds, user.course_id_list)
    message.success('Students removed successfully');

    setStudents(prevStudents => prevStudents.filter(student => !studentIds.includes(student._id))); 
    user.assigned_student_id_list = user.assigned_student_id_list.filter((assigned_id: string) => !studentIds.includes(assigned_id));
    localStorage.setItem('user', JSON.stringify(user));
    setSelectedRowKeys([]);
    setLoading(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(',', '');
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Last Updated',
      dataIndex: 'last_updated',
      key: 'last_updated',
      render: (text: string) => formatDate(text),
    },
    {
      title: 'Remove Student',
      key: 'action',
      render: (_: any, record: Student, index: number) => (
        <Button
        data-testid={`remove-button-${index}`} 
          onClick={() => removeStudents([record._id])}
          danger
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <Content style={{ padding: "2rem" }}>
      <Typography.Title level={1} style={{ marginBottom: '1rem' }}>
        Assigned Students
      </Typography.Title>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={students}
            rowKey="_id"
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <Button
              type="primary"
              danger
              onClick={() => removeStudents(selectedRowKeys.map(String))}
              disabled={!selectedRowKeys.length}
            >
              Remove Selected Students
            </Button>
          </div>
        </>
      )}
    </Content>
  );
};

export default AssignedStudents;