import React, { useEffect } from 'react';
import { Modal, Form, Input, Typography, InputNumber } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { convertTimeToSeconds } from '../../utils/string_functions';
import { FlexMarginButton } from '../basic/buttons';
import { CourseFile } from '../../services/courses';
import { audioFormats } from '../../config';

const { Text } = Typography;

const generateSpecFileName = (
  originalName: string,
  startPage: number,
  endPage: number
): string => {
  const nameParts = originalName.split('.');
  const extension = nameParts.pop() || '';
  const baseName = nameParts.join('.');
  let isAudio = audioFormats.includes(extension);
  if (isAudio) {
    return `${baseName} (Time ${startPage} - ${endPage}).${extension}`;
  }
  return `${baseName} (Pages ${startPage} - ${endPage}).${extension}`;
};

const isSpecFileExists = (
  originalName: string,
  startPage: number,
  endPage: number,
  existingFiles: CourseFile[]
): boolean => {
  const specFileName = generateSpecFileName(originalName, startPage, endPage);
  return existingFiles.some(file => file.file_name === specFileName);
};

export interface FileSpecification {
  startPage?: number;
  endPage?: number;
}

interface FileMetadata {
  fileType: 'document' | 'media' | 'other';
  pages?: number;
  duration?: number;
}

interface FileSpecificationOverlayProps {
  file: UploadFile<any>;
  metadata: FileMetadata | null;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (specs: FileSpecification) => void;
  onSkip: () => void;
  existingFiles: CourseFile[];
}

const FileSpecificationOverlay: React.FC<FileSpecificationOverlayProps> = ({
  file,
  metadata,
  visible,
  onCancel,
  onSubmit,
  onSkip,
  existingFiles
}) => {
  const [form] = Form.useForm();
  const timePattern = /^([0-5][0-9]):([0-5][0-9])$/;

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const validatePages = async (_: any, value: number) => {
    if (!value) return Promise.resolve();

    try {
      const formValues = form.getFieldsValue();
      if (Boolean(metadata!.fileType === 'document' && metadata!.pages)) {
        const startPage = formValues.startPage;
        const endPage = formValues.endPage;
        if (startPage && endPage && file) {
          if (isSpecFileExists(file.name, startPage, endPage, existingFiles)) {
            return Promise.reject(new Error('A file with these page specifications already exists'));
          }
        }
      }
      if (Boolean(metadata!.fileType === 'media' && metadata!.duration)) {
        const startPage = convertTimeToSeconds(formValues.startTime);
        const endPage = convertTimeToSeconds(formValues.endTime);
        if (startPage && endPage && file) {
          if (isSpecFileExists(file.name, startPage, endPage, existingFiles)) {
            return Promise.reject(new Error('A file with these time specifications already exists'));
          }
        }
      }
    } catch (error) {
      return Promise.resolve();
    }

    return Promise.resolve();
  };

  const handleSubmit = () => {
    form.validateFields().then(values => {
      onSubmit(values);
      form.resetFields();
    });
  };

  const renderContent = () => {
    if (!file || !metadata) return null;

    if (metadata.fileType === 'document' && metadata.pages) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Text>
            The file {file.name} has {metadata.pages} pages. Please specify the page range to process:
          </Text>
          <Form
            form={form}
            layout="vertical"
            style={{ width: '100%', marginTop: '20px' }}
          >
            <Form.Item
              name="startPage"
              label="Start Page"
              rules={[
                { required: true, message: 'Please input the start page!' },
                {
                  validator: (_, value) =>
                    value > 0 ? Promise.resolve() : Promise.reject(new Error('Start page must be greater than 0'))
                },
                { validator: validatePages }
              ]}
            >
              <InputNumber
                data-testid="start-page-input"
                min={1}
                max={metadata.pages}
                style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="endPage"
              label="End Page"
              rules={[
                { required: true, message: 'Please input the end page!' },
                {
                  validator: async (_, value) => {
                    if (!value) return Promise.resolve();
                    const startPage = form.getFieldValue('startPage');
                    if (startPage && value <= startPage) {
                      return Promise.reject(new Error('End page must be greater than start page'));
                    }
                    if (value > metadata.pages!) {
                      return Promise.reject(new Error('End page cannot exceed document length'));
                    }
                    return validatePages(_, value);
                  }
                }
              ]}
            >
              <InputNumber
                data-testid="end-page-input"
                min={1}
                max={metadata.pages}
                style={{ width: '100%' }} />
            </Form.Item>
          </Form>
        </div>
      );
    }

    if (metadata.fileType === 'media' && metadata.duration) {
      const formatTime = (seconds: number) => {
        return new Date(seconds * 1000).toISOString().substr(14, 5); // Get MM:SS format
      };

      return (
        <>
          <Text>
            The file {file.name} is {formatTime(metadata.duration)} long. Please specify the time range to process:
          </Text>
          <Form
            form={form}
            layout="vertical"
            style={{ width: '100%', marginTop: '20px' }}
          >
            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[
                { required: true, message: 'Please input the start time!' },
                { pattern: timePattern, message: 'Please use MM:SS format!' },
                {
                  validator: (_, value) =>
                    convertTimeToSeconds(value) > 0 ? Promise.resolve() : Promise.reject(new Error('Start time must be greater than 0'))
                },
                { validator: validatePages }
              ]}
            >
              <Input placeholder="MM:SS" />
            </Form.Item>
            <Form.Item
              name="endTime"
              label="End Time"
              rules={[
                { required: true, message: 'Please input the end time!' },
                { pattern: timePattern, message: 'Please use MM:SS format!' },
                {
                  validator: (_, value) => {
                    if (!value) return Promise.resolve();
                    const endSeconds = convertTimeToSeconds(value);
                    if (metadata.duration && endSeconds > metadata.duration) {
                      return Promise.reject(new Error('End time cannot exceed media duration!'));
                    }
                    const startTime = form.getFieldValue('startTime');
                    if (startTime) {
                      const startSeconds = convertTimeToSeconds(startTime);
                      if (endSeconds <= startSeconds) {
                        return Promise.reject(new Error('End time must be after start time!'));
                      }
                    }
                    return Promise.resolve();
                  }
                },
                { validator: validatePages }
              ]}
            >
              <Input placeholder="MM:SS" />
            </Form.Item>
          </Form>
        </>
      );
    }

    return null;
  };

  return (
    <Modal
      title="File Processing Specifications"
      open={visible}
      onCancel={onCancel}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FlexMarginButton key="cancel" onClick={onCancel}>
              Cancel
            </FlexMarginButton>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FlexMarginButton key="skip" onClick={onSkip}>
              Process Entire File
            </FlexMarginButton>
            <FlexMarginButton
              key="submit"
              type="primary"
              onClick={handleSubmit}
            >
              Process Selected Range
            </FlexMarginButton>
          </div>
        </div>
      }
    >
      {renderContent()}
    </Modal>
  );
};

export default FileSpecificationOverlay;