import { LoadingOutlined, UpCircleFilled, UpCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import { Template } from '../../services/templates';
import TemplateMenu from './TemplateMenu';
import { SendButton } from '../basic/send-container';
import { styled } from '@stitches/react';

const ChatInputContainer = styled("div", {
    position: "relative", 
    display: "flex",
    flexDirection: "column",
    minHeight: "fit-content",
    bottom: "24px",
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: "calc(100% - 48px)",
    minWidth: "calc(100% - 48px)",
    transition: '0.2s ease-in-out',
    zIndex: 10,
    padding: "12px",
    paddingLeft: "0px",
    paddingTop: "0px",
    margin: "0px",
    boxSizing: "border-box"
});

interface ChatInputProps {
    input: string;
    setInput: (value: string) => void;
    isLoading: boolean;
    sendMessage: () => void;
    handleKeyDown: (e: any) => void;
    templates: Template[];
    onUpdateTemplates: (updatedTemplates: Template[]) => void;
    chatTypeSpecifier: string;
    handleSetMessageText: (text: string) => void;
    selectedWeeks: number[];
    token: any;
    siderCollapsed: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
    input,
    setInput,
    isLoading,
    sendMessage,
    handleKeyDown,
    templates,
    onUpdateTemplates,
    chatTypeSpecifier,
    handleSetMessageText,
    selectedWeeks,
    token,
    siderCollapsed
}) => {
    return (
        <ChatInputContainer
            style={{ width: siderCollapsed ? "70%" : "50%" }}
        >
            <div style={{
                display: "flex",
                gap: "12px",
                justifyContent: "flex-end",
                alignItems: "flex-end",
            }}>
                <Input.TextArea
                    size="large"
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    placeholder="Send message..."
                    value={input}
                    onChange={e => setInput(e.target.value.replace(/^\s+/, ''))}
                    onKeyDown={handleKeyDown}
                    style={{
                        borderRadius: '0.75rem',
                        width: '100%',
                        resize: 'none',
                        maxHeight: '4.5rem',
                        overflowY: 'auto'
                    }}
                />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "48px",
                    gap: "4px",
                }}>
                    <TemplateMenu
                        onSetMessageText={handleSetMessageText}
                        templates={templates}
                        onUpdateTemplates={onUpdateTemplates}
                        chatTypeSpecifier={chatTypeSpecifier}
                    />

                    <Tooltip
                        title={isLoading ? "Please wait while the message is being processed" :
                            selectedWeeks.length === 0 ? "Please select at least one module" : "Press Shift + Enter to create a new line"}
                    >
                        <SendButton
                            data-testid="send-button"
                            size="large"
                            type="text"
                            disabled={isLoading || selectedWeeks.length === 0}
                            onClick={sendMessage}
                            style={{
                                transition: 'background-color 0.3s ease',
                                color: token.colorPrimary,
                                position: 'relative',
                                right: '0',
                                bottom: '0px'
                            }}
                            onMouseEnter={(event: { currentTarget: { style: { backgroundColor: string; color: any; }; }; }) => {
                                event.currentTarget.style.backgroundColor = 'transparent';
                                event.currentTarget.style.color = token.colorPrimaryHover;
                            }}
                            onMouseLeave={(event: { currentTarget: { style: { backgroundColor: string; color: any; }; }; }) => {
                                event.currentTarget.style.backgroundColor = 'transparent';
                                event.currentTarget.style.color = token.colorPrimary;
                            }}
                        >
                            {isLoading ? <LoadingOutlined style={{ color: token.colorPrimary }} /> :
                                input.length > 0 ? <UpCircleFilled style={{ fontSize: '24px' }} /> :
                                    <UpCircleOutlined style={{ fontSize: '24px' }} />}
                        </SendButton>
                    </Tooltip>
                </div>
            </div>
        </ChatInputContainer>
    );
};

export default ChatInput;