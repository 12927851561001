export const changelogContent = `## Coming 
- 01.01.2025: Introducing advanced analytics with insights gained from sentimnt analysis and granular metrics. The analytics will be packaged into 4 major areas: sentiment, understanding, engagement and content quality. They will be available as charts and via a chat with Atla. Additional tooling will be provided to Atla to enable better responses.
- 01.01.2025: Improved seurity with Clerk.

## Added
- 09.12.2024: Atla is now able to generate content based on the course material summaries. Just ask it!
- 06.12.2024: Improved the upload speed.

## Changed 
- 16.12.2024: UI improvements in the chat interface and course editing menu.
  - Send message with Enter, break line with Shift + Enter.
  - Prompt Templates available as menu next to the send button.
  - Sidebar in the chat interface is now smoother and les cluttered. 
- 14.12.2024: Loading time improvements for thechat history.

## Fixed 
- 17.12.2024: Auth token timeout created "Invalid Token" messages in the chat interface. Fixed.
- 17.12.2024: Sidebar in the chat interface did not load Group and Material items initially. Fixed.
- 17.12.2024: Chat message containing bullet points with the bullet point symbol was not formatting these are a list. Fixed.
- 16.12.2024: File download failed. Fixed.
- 16.12.2024: More minor UI bugs fixed.

## Security 
- All suppliers fulfil SOC Type 2 and GDPR requirements and have been certified.`;