import React from 'react';
import { Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';

interface NavigationGuardProps {
  onNavigate: () => void;
  targetPath: string;
}

const NavigationGuard: React.FC<NavigationGuardProps> = ({ onNavigate, targetPath }) => {
  const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = () => {
    if (hasUnsavedChanges && targetPath !== location.pathname) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes. Are you sure you want to leave this page?',
        okText: 'Leave',
        cancelText: 'Stay',
        onOk: () => {
          setHasUnsavedChanges(false);
          onNavigate();
          navigate(targetPath);
        },
        onCancel: () => {
          onNavigate();
        },
      });
    } else {
      onNavigate();
      navigate(targetPath);
    }
  };

  React.useEffect(() => {
    handleNavigation();
  }, []);

  return null;
};

export default NavigationGuard;