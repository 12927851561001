import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import { styled } from "@stitches/react";
import { Avatar, Button, message, theme } from "antd";
import 'katex/dist/katex.min.css'; // Import KaTeX CSS
import { memo, useEffect, useState } from "react";
import { dislikeMessage, likeMessage } from "../../services/conversations";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { preprocessLatex } from "../../utils/utils";
import { atla_logo_path, chatFormatContent, sona_logo_path } from "../../config";
import SourceButton, { SourceItem } from "./SourceButton";
import Markdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'


const MessageBubbleContainer = styled("div", {
    maxWidth: '100%', // Ensure the bubble doesn't exceed the container width
    flexShrink: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "10px",
    padding: "10px",

    "& #answer > :first-child ": {
        marginTop: "0 !important",
        paddingTop: "0 !important",
    },
});

const TextContentContainer = styled("div", {
    width: '100%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    '& .markdown-body': {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        '& > :first-child': {
            marginTop: '0 !important',
            paddingTop: '0 !important',
        },
        '& > :last-child': {
            marginBottom: '0 !important',
            paddingBottom: '0 !important',
        },
    },
});

const StreamingContent = memo(({
    text,
    sourceItems,
    conversationId
}: {
    text: string,
    sourceItems: SourceItem[],
    conversationId: string
}) => (
    <TextContentContainer>
        <Markdown remarkPlugins={[]} rehypePlugins={[rehypeKatex]}>
            {text}
        </Markdown>
        {sourceItems.map((item, index) => (
            <SourceButton
                key={`${item.fileId}-${index}`}
                conversationId={conversationId}
                fileId={item.fileId}
                sourceText={item.sourceText}
            />
        ))}
    </TextContentContainer>
));

const LikeDislikeContainer = styled("div", {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem"
});

const Flex = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
})


export const MessageBubble = ({
    conversationId,
    msgId,
    user,
    text,
    sources,
    type,
    isLoading }: {
        conversationId: string,
        msgId: string,
        user: "user" | "bot" | "system",
        text: string,
        sources: string[],
        type: string,
        isLoading: boolean
    }) => {
    const { token } = theme.useToken();
    const userData = getUserDataFromLocalStorage();
    const [logoData, setLogoData] = useState<string | undefined>(undefined);
    const [mainText, setMainText] = useState<string>('');
    const [sourceItems, setSourceItems] = useState<SourceItem[]>([]);
    const [likeLoading, setLikeLoading] = useState<boolean>(false);
    const [dislikeLoading, setDislikeLoading] = useState<boolean>(false);

    useEffect(() => {
        // Split the text into main content and source items
        const parts = text.split('<source_button>');
        const mainContent = parts[0];
        const sourcesItems: SourceItem[] = [];

        // Process each source button part after the first split
        if (parts.length > 1) {
            parts.slice(1).forEach(part => {
                const endIndex = part.indexOf('</source_button>');
                if (endIndex !== -1) {
                    const sourceContent = part.substring(0, endIndex);
                    const [fileId, sourceText] = sourceContent.split(':');
                    sourcesItems.push({
                        fileId: fileId.trim(),
                        sourceText: sourceText.trim()
                    });
                }
            });
        } else {
            // set sources as input attribute sources if no empty
            if (sources.length > 0) {
                sources.forEach(source => {
                    source = source.replace('<source_button>', '');
                    source = source.replace('</source_button>', '');
                    const [fileId, sourceText] = source.split(':');
                    sourcesItems.push({
                        fileId: fileId.trim(),
                        sourceText: sourceText.trim()
                    });
                });
            }
        }

        // replace all \u2022 with - due to markdown ignoring the char
        // https://stackoverflow.com/questions/54768905/markdown-with-unicode-bullets-itemization-marks
        setMainText(preprocessLatex(mainContent).replace(/\u2022/g, '-'));
        setSourceItems(sourcesItems);
    }, [text, sources]);

    useEffect(() => {
        setLogoData(userData.config.logo);
    }, [user, userData.config.logo]);

    function handleLike() {
        setLikeLoading(true);
        likeMessage(conversationId, text)
            .then(() => {
                message.success("Thank you for the feedback!");
            })
            .catch(err => {
                message.error("Error liking message: " + err);
                console.error(err);
            })
            .finally(() => setLikeLoading(false));
    }

    function handleDislike() {
        setDislikeLoading(true);
        dislikeMessage(conversationId, text)
            .then(() => {
                message.success("Thank you for the feedback!");
            })
            .catch(err => {
                message.error("Error disliking message: " + err);
                console.error(err);
            })
            .finally(() => setDislikeLoading(false));
    }

    return (
        <MessageBubbleContainer>
            <Avatar
                size="small"
                src={(user === "bot" || user === "system") && (type === chatFormatContent ? sona_logo_path : atla_logo_path)}
                style={{
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    marginTop: "-3px",
                    backgroundColor: user === "user" ? token.colorBgSpotlight : "transparent"
                }}
            >
                {user === "user" && userData.name[0]}
            </Avatar>
            <Flex>
                <b>{user === "user" ? "You" : (type === chatFormatContent ? "Sona" : "Atla")}</b>
                <StreamingContent text={mainText} sourceItems={sourceItems} conversationId={conversationId} />
                {user === "bot" && !isLoading && (
                    <LikeDislikeContainer>
                        <Button loading={likeLoading} type="text" icon={<LikeOutlined />} onClick={handleLike} />
                        <Button loading={dislikeLoading} type="text" icon={<DislikeOutlined />} onClick={handleDislike} />
                    </LikeDislikeContainer>
                )}
            </Flex>
        </MessageBubbleContainer>
    );
};