import * as PDFJS from 'pdfjs-dist';
import { duration_threshold, page_threshold } from '../../config';

// PDFJS.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${PDFJS.version}/build/pdf.worker.mjs`;
PDFJS.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${PDFJS.version}/build/pdf.worker.mjs`;

// Supported document types
const SUPPORTED_DOCS = {
  PDF: 'application/pdf',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ODT: 'application/vnd.oasis.opendocument.text'
};

// Supported media types (both audio and video)
const SUPPORTED_MEDIA = [
  'audio/mpeg',  // MP3
  'audio/wav',   // WAV
  'audio/aac',   // AAC
  'audio/ogg',   // OGG
  'video/mp4',   // MP4
  'video/webm',  // WebM
  'video/ogg'    // OGV
];

interface FileAnalysis {
  requiresSpecification: boolean;
  type: 'document' | 'media' | 'other';
  pages?: number;
  duration?: number;
}



const getDocumentPages = async (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    switch(file.type) {
      case SUPPORTED_DOCS.PDF:
        reader.onload = async (e) => {
          if (!e.target?.result) {
            reject(new Error('Failed to read file'));
            return;
          }

          try {
            const typedarray = new Uint8Array(e.target.result as ArrayBuffer);
            const loadingTask = PDFJS.getDocument({ data: typedarray });
            const pdf = await loadingTask.promise;
            resolve(pdf.numPages);
          } catch (error) {
            console.error('PDF loading error:', error);
            reject(error);
          }
        };

        reader.onerror = () => {
          reject(new Error('Failed to read file'));
        };

        reader.readAsArrayBuffer(file);
        break;

      default:
        resolve(0);
    }
  });
};

const getMediaDuration = async (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    if (!SUPPORTED_MEDIA.includes(file.type)) {
      resolve(0);
      return;
    }

    const url = URL.createObjectURL(file);
    const media = file.type.startsWith('audio/') ? new Audio() : document.createElement('video');
    
    const loadPromise = new Promise<number>((resolveLoad, rejectLoad) => {
      const cleanup = () => {
        URL.revokeObjectURL(url);
        media.remove();
      };

      media.addEventListener('loadedmetadata', () => {
        const duration = media.duration;
        cleanup();
        resolveLoad(duration);
      }, { once: true });

      media.addEventListener('error', () => {
        cleanup();
        rejectLoad(new Error(`Failed to load media file: ${media.error?.message || 'Unknown error'}`));
      }, { once: true });
    });

    const timeoutPromise = new Promise<never>((_, rejectTimeout) => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        rejectTimeout(new Error('Timeout while loading media metadata'));
      }, 10000);
    });

    media.preload = 'metadata';
    media.src = url;

    Promise.race([loadPromise, timeoutPromise])
      .then(resolve)
      .catch(reject);
  });
};

export const analyzeFile = async (file: File): Promise<FileAnalysis> => {
  try {
    // Check if it's a supported document type
    if (Object.values(SUPPORTED_DOCS).includes(file.type)) {
      const pages = await getDocumentPages(file);
      return {
        requiresSpecification: pages > page_threshold,
        type: 'document',
        pages
      };
    }

    // Check if it's a supported media type
    if (SUPPORTED_MEDIA.includes(file.type)) {
      const duration = await getMediaDuration(file);
      return {
        requiresSpecification: duration > duration_threshold,
        type: 'media',
        duration
      };
    }

    // For all other file types
    return {
      requiresSpecification: false,
      type: 'other'
    };
  } catch (error) {
    console.error('Error analyzing file:', error);
    // If analysis fails, don't require specification
    return {
      requiresSpecification: false,
      type: 'other'
    };
  }
};