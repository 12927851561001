import { MessageFilled } from "@ant-design/icons";
import { Layout, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CourseCard from "../components/course/course-card";
import { newConversation } from "../services/conversations";
import { CourseOverview, getUserCourseOverview } from "../services/courses";
import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import { CardsContainer } from "./courses/course-overview";

const { Content } = Layout;

const StudentCourseGallery = () => {
    const user = getUserDataFromLocalStorage();
    const [courses, setCourses] = useState<CourseOverview[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const coursesData = await getUserCourseOverview(user._id, user.course_id_list);
                setCourses(coursesData);
            } catch (error) {
                console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}s:`, error);
            }
        }
        fetchCourses().then(() => setIsLoading(false));
    }, []);

    return (
        <Content style={{ padding: "2rem" }}>
            {isLoading && <Spin size="large" />}
            <CardsContainer>
                {courses.map(course => (
                    <CourseCard
                        key={course._id}
                        id={course._id}
                        title={course.name}
                        description={course.description}
                        professors={course.professors}
                        lastUpdated={course.last_updated}
                        cta={{
                            text: "Chat",
                            icon: <MessageFilled />,
                            onClick: () => {
                                newConversation(course._id!, user._id, "content").then(chat => navigate("/chat/" + chat._id));
                            },
                        }}
                    />
                ))}
            </CardsContainer>
        </Content>
    );
}

export default StudentCourseGallery