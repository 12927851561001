import { DeleteOutlined, MinusOutlined, PlusOutlined, FunnelPlotOutlined, SnippetsOutlined, EditOutlined, BarsOutlined, FileAddOutlined, FileTextOutlined } from "@ant-design/icons";
import { styled } from "@stitches/react";
import { Checkbox, Input, Table, Row, DatePicker, message, theme, Tooltip, Collapse, Space, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../screens/courses/course";
import { Week, WeekMaterial, summarizeWeek } from "../../services/courses";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { audioFormats, noPageFormats } from "../../config";
import { FlexMarginButton } from "../basic/buttons";
import { capitalize, hexToRGBA } from "../../utils/utils";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import AssignmentOverlay from "./assignment-workflow-overlay";
import TiptapEditor from "../basic/TipTapEditor";
import { convertSecondsToTime, convertTimeToSeconds, isEmptyOrOnlyTags } from "../../utils/string_functions";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const WeekContainer = styled('div', {
    margin: '1rem 0',
    padding: "1rem 2rem",
    borderRadius: '8px',
})


const PlaceholderArea = styled('div', {
    width: '100%',
    height: '200px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 10px, rgba(255, 255, 255, 0) 10px, rgba(255, 255, 255, 0) 20px)',
        zIndex: 0,
    }
})

export const Weeks = () => {
    const user = getUserDataFromLocalStorage();
    const { token } = theme.useToken();
    const { tempCourse: course, setTempCourse, saveCourseChanges, courseFiles, fetchCourseFiles, reloadSummaryPeriodically } = useContext(CourseContext)
    const [showAssignmentOverlay, setShowAssignmentOverlay] = useState<boolean[]>([]);
    const [assignmentIsSet, setAssignmentIsSet] = useState<boolean[]>([]);
    const [summaryIsBeingGenerated, setSummaryIsBeingGenerated] = useState<boolean[]>([]);
    const [summaryIsSet, setSummaryIsSet] = useState<boolean[]>([]);
    const [openDateRangeIndex, setOpenDateRangeIndex] = useState<number | null>(null);
    const [currentWeekIndex, setCurrentWeekIndex] = useState<number | null>(null);
    const [editingWeekTitle, setEditingWeekTitle] = useState<number | null>(null);
    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    useEffect(() => {
        const loadAndProcessCourseFiles = async () => {
            if (course?._id && courseFiles.length === 0) {
                await fetchCourseFiles(course._id);

                if (courseFiles.length > 0) {
                    setTempCourse(prevCourse => {
                        if (!prevCourse) return prevCourse;
                        const updatedWeeks = prevCourse.weeks.map(week => ({
                            ...week,
                            materials: week.materials.filter(material =>
                                courseFiles.some(file => file._id === material.file_id)
                            ),
                        }));
                        return { ...prevCourse, weeks: updatedWeeks };
                    });
                }
            }
        };

        loadAndProcessCourseFiles();
    }, []);

    useEffect(() => {
        if (course?.weeks) {
            setSummaryIsSet(course.weeks.map(week => !isEmptyOrOnlyTags(week.summary)));
            setAssignmentIsSet(course.weeks.map(week => !isEmptyOrOnlyTags(week.assignment)));
        }
    }, [course?.weeks]);

    function createWeek() {
        setTempCourse(course => {
            const courseWeeks = [...(course?.weeks || [])]
            courseWeeks.push(
                {
                    number: courseWeeks.length + 1,
                    title: '',
                    materials: [],
                    topics: [],
                    assignment: '',
                    start_date: '',
                    end_date: '',
                    summary: ''
                }
            )

            setTimeout(() => {
                setOpenDateRangeIndex(courseWeeks.length - 1);
            }, 0);
            return { ...course!, weeks: courseWeeks }
        });

        setActiveKeys(prevKeys => {
            const newIndex = prevKeys.length.toString();
            return [...prevKeys, newIndex];
        });
    }


    function removeLastWeek() {
        setTempCourse(course => {
            const newWeeks = course?.weeks.slice(0, -1);
            return { ...course!, weeks: newWeeks! };
        });

        setActiveKeys(prevKeys => prevKeys.slice(0, -1));
    }


    const renderMaterialsTab = (weekIndex: number, week: Week) => (
        <>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {courseFiles.filter(
                    f => !course!.weeks[weekIndex].materials.map(
                        m => m.file_id).includes(f._id)).map(
                            f => <FlexMarginButton
                                data-testid={`add-material-${f.file_name}-to-week-${weekIndex}`}
                                size="large"
                                icon={<PlusOutlined />}
                                onClick={() => addMaterialToWeek(f._id, weekIndex)}>{f.file_name}
                            </FlexMarginButton>)
                }
            </div>

            <Table
                dataSource={week.materials}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: "All Pages?",
                        dataIndex: 'spans_fully',
                        key: 'spans_fully',
                        render: (_: any, record: WeekMaterial, materialIndex: number) => (
                            <Checkbox
                                data-testid={`spans-fully-checkbox-${materialIndex}-week-${weekIndex}`}
                                checked={week.materials[materialIndex].spans_fully}
                                onChange={e => changeWeekMaterial(weekIndex, record.file_id,
                                    { spans_fully: Boolean(e.target.checked), span_start: courseFiles.find(f => f._id === record.file_id)!.start_page, span_end: courseFiles.find(f => f._id === record.file_id)!.end_page })}
                                disabled={noPageFormats.some(format => week.materials[materialIndex].name.endsWith(format))} />
                        ),
                    },
                    {
                        title: "First Relevant Page / Time",
                        dataIndex: 'span_start',
                        key: 'span_start',
                        render: (_: any, record: WeekMaterial, materialIndex: number) => (
                            <Input
                                data-testid={`span-start-input-${materialIndex}-week-${weekIndex}`}
                                disabled={week.materials[materialIndex].spans_fully}
                                value={record.file_type === 'audio'
                                    ? convertSecondsToTime(week.materials[materialIndex].span_start)
                                    : week.materials[materialIndex].span_start?.toString() || ''}
                                onChange={e => changeWeekMaterial(weekIndex, record.file_id, { span_start: convertTimeToSeconds(e.target.value) })}
                                placeholder={record.file_type === 'audio' ? "Minute : Second (mm:ss)" : "Page number"}
                            />
                        ),
                    },
                    {
                        title: "Last Relevant Page / Time",
                        dataIndex: 'span_end',
                        key: 'span_end',
                        render: (_: any, record: WeekMaterial, materialIndex: number) => (
                            <Input
                                data-testid={`span-end-input-${materialIndex}-week-${weekIndex}`}
                                disabled={week.materials[materialIndex].spans_fully}
                                value={record.file_type === 'audio'
                                    ? convertSecondsToTime(week.materials[materialIndex].span_end)
                                    : week.materials[materialIndex].span_end?.toString() || ''}
                                onChange={e => changeWeekMaterial(weekIndex, record.file_id, { span_end: convertTimeToSeconds(e.target.value) })}
                                placeholder={record.file_type === 'audio' ? "MM:SS" : "Page number"}
                            />
                        )
                    },
                    {
                        title: "Group",
                        dataIndex: 'group',
                        key: 'group',
                        render: (_: any, record: WeekMaterial, materialIndex: number) => (
                            <Input
                                data-testid={`group-input-${materialIndex}-week-${weekIndex}`}
                                value={week.materials[materialIndex].group || ''}
                                onChange={e => changeWeekMaterial(weekIndex, record.file_id, { group: e.target.value })}
                                placeholder={''}
                            />
                        )
                    },
                    {
                        title: '',
                        dataIndex: 'delete',
                        key: 'delete',
                        render: (_: any, record: WeekMaterial) => (
                            <FlexMarginButton
                                data-testid="delete-button"
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    removeMaterialFromWeek(record.file_id, weekIndex)
                                }}
                            />
                        ),
                    },
                ]} />
        </>
    );



    const renderTopicsTab = (weekIndex: number) => (
        <>
            {course!.weeks[weekIndex].topics.map((topic, topicIndex) => (
                <Row key={topicIndex} style={{ width: '100%', marginBottom: '10px', justifyContent: 'space-between' }}>
                    <Input
                        placeholder="Topic name"
                        value={topic.name}
                        onChange={(e) => updateTopic(weekIndex, topicIndex, 'name', e.target.value)}
                        style={{ width: '15%' }}
                    />
                    <Input.TextArea
                        placeholder="Topic Description"
                        value={topic.description}
                        onChange={(e) => updateTopic(weekIndex, topicIndex, 'description', e.target.value)}
                        style={{ width: '80%' }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            width: '3%'
                        }}
                    >
                        <FlexMarginButton
                            icon={<DeleteOutlined />}
                            onClick={() => removeTopic(weekIndex, topicIndex)}
                            data-testid={`remove-topic-button-${topicIndex}-week-${weekIndex}`}
                            style={{
                                height: '100%',
                            }}
                        />
                    </div>
                </Row>
            ))}
            <FlexMarginButton
                icon={<PlusOutlined />}
                onClick={() => addTopic(weekIndex)}
            >
                Add Topic
            </FlexMarginButton>
        </>
    );

    const renderSummaryTab = (weekIndex: number) => (
        <>
            {!summaryIsSet[weekIndex] ? (
                <PlaceholderArea
                    style={{
                        border: `2px dashed ${token.colorPrimaryBorder}`,
                    }}
                >
                    <Tooltip title={course!.weeks[weekIndex].materials.length === 0
                        ? "Please arrange your materials before generating the summary"
                        : "Generate the summary of all the materials in this module with AI or set it yourself. Remove it to regenerate."}>
                        <FlexMarginButton
                            data-testid={`summarize-week-button-${weekIndex}`}
                            icon={<FunnelPlotOutlined />}
                            onClick={() => { handleSummarizeWeek(weekIndex) }}
                            disabled={course!.weeks[weekIndex].materials.length === 0 || summaryIsBeingGenerated[weekIndex]}
                        >
                            {!summaryIsBeingGenerated[weekIndex] ? `Summarize ${user.config.module_terminology.toLowerCase()}` : 'Generating Summary...'}
                        </FlexMarginButton>
                    </Tooltip>
                </PlaceholderArea>
            ) : (
                <TiptapEditor
                    tiptap_testid={`summarize-week-area-${weekIndex}`}
                    value={course!.weeks[weekIndex].summary}
                    onChange={(value) => handleModifyingSummary(weekIndex, value)}
                />
            )}
        </>
    );

    const renderAssignmentTab = (weekIndex: number) => (
        <>
            {!assignmentIsSet[weekIndex] ? (
                <PlaceholderArea
                    style={{
                        border: `2px dashed ${token.colorPrimaryBorder}`,
                    }}
                >
                    <Tooltip title={course!.weeks[weekIndex].summary.includes('Summarizing') || isEmptyOrOnlyTags(course!.weeks[weekIndex].summary)
                        ? "Generate a summary for the module first."
                        : "Click here to set the assignment for this module. Sona will make it available to your students."}>
                        <FlexMarginButton
                            data-testid={`set-assignment-button-${weekIndex}`}
                            icon={<SnippetsOutlined />}
                            onClick={() => toggleSetAssignment(weekIndex)}
                            disabled={course!.weeks[weekIndex].summary.includes('Summarizing') || isEmptyOrOnlyTags(course!.weeks[weekIndex].summary)}
                        >
                            Set Assignment Message
                        </FlexMarginButton>
                    </Tooltip>
                </PlaceholderArea>
            ) : (
                <TiptapEditor
                    value={course!.weeks[weekIndex].assignment}
                    onChange={(value) => handleSetAssignment(weekIndex, value)}
                />
            )}
            {showAssignmentOverlay[weekIndex] && (
                <AssignmentOverlay
                    visible={showAssignmentOverlay[weekIndex]}
                    onClose={() => handleAssignmentOverlayClose(weekIndex)}
                    onSetAssignment={(assignment) => {
                        if (currentWeekIndex !== null) {
                            handleSetAssignment(currentWeekIndex, assignment);
                        }
                    }}
                    moduleNumber={currentWeekIndex !== null ? currentWeekIndex + 1 : 0}
                    courseId={course?._id!}
                    weekIndex={weekIndex}
                    moduleSummary={course?.weeks[weekIndex].summary!}
                />
            )}
        </>
    );



    function handleWeekTitleChange(weekIndex: number, newTitle: string) {
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse;
            const newWeeks = [...prevCourse.weeks];
            newWeeks[weekIndex] = { ...newWeeks[weekIndex], title: newTitle };
            return { ...prevCourse, weeks: newWeeks };
        });
    }

    function addTopic(weekIndex: number) {
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse;
            const newWeeks = [...prevCourse.weeks];
            const newTopics = [...(newWeeks[weekIndex].topics || []), { name: '', description: '' }];
            newWeeks[weekIndex] = { ...newWeeks[weekIndex], topics: newTopics };
            return { ...prevCourse, weeks: newWeeks };
        });
    }

    function removeTopic(weekIndex: number, topicIndex: number) {
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse;
            const newWeeks = [...prevCourse.weeks];
            const newTopics = newWeeks[weekIndex].topics.filter((_, index) => index !== topicIndex);
            newWeeks[weekIndex] = { ...newWeeks[weekIndex], topics: newTopics };
            return { ...prevCourse, weeks: newWeeks };
        });
    }

    function updateTopic(weekIndex: number, topicIndex: number, field: 'name' | 'description', value: string) {
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse;
            const newWeeks = [...prevCourse.weeks];
            const newTopics = [...newWeeks[weekIndex].topics];
            newTopics[topicIndex] = { ...newTopics[topicIndex], [field]: value };
            newWeeks[weekIndex] = { ...newWeeks[weekIndex], topics: newTopics };
            return { ...prevCourse, weeks: newWeeks };
        });
    }

    function changeWeekMaterial(weekIndex: number, file_id: string, change: Partial<WeekMaterial>) {
        setTempCourse(course => {
            const newWeeks = course!.weeks;
            const week = newWeeks[weekIndex];
            const materialIndex = week.materials.findIndex(m => m.file_id === file_id);
            const material = { ...week.materials[materialIndex] };
            newWeeks[weekIndex].materials[materialIndex] = { ...material, ...change };
            return { ...course!, weeks: newWeeks };
        });
    }


    function addMaterialToWeek(fileId: string, weekIndex: number) {
        if (course!.weeks[weekIndex].materials.map(m => m.file_id).includes(fileId)) {
            return
        }
        setTempCourse(course => {
            const newWeeks = [...course!.weeks];
            const newMaterials = [...newWeeks[weekIndex].materials];
            const file = courseFiles.find(f => f._id === fileId);
            if (!audioFormats.some(ext => file!.file_name.endsWith(ext))) {
                newMaterials.push({
                    name: file!.file_name,
                    file_id: fileId,
                    spans_fully: true,
                    span_start: file!.start_page,  // page numbers start with 1
                    span_end: file!.end_page,
                    file_type: 'book',
                    group: ''
                });
            } else {
                newMaterials.push({
                    name: file!.file_name,
                    file_id: fileId,
                    spans_fully: true,
                    span_start: file!.start_page,  // page numbers for audio files are seconds
                    span_end: file!.end_page,
                    file_type: 'audio',
                    group: ''
                });
            }
            newWeeks[weekIndex] = { ...newWeeks[weekIndex], materials: newMaterials };
            return { ...course!, weeks: newWeeks };
        })
    }

    function handleSummarizeWeek(weekIndex: number) {
        // Save the course before handling the summary
        saveCourseChanges()
            .then(() => {
                setSummaryIsBeingGenerated(prev => {
                    const newSummaryIsBeingGenerated = [...prev];
                    newSummaryIsBeingGenerated[weekIndex] = true;
                    return newSummaryIsBeingGenerated;
                });
                return summarizeWeek(course!._id, weekIndex + 1);
            })
            .then(() => {
                message.success('Hang on, the summary is being generated!');
                // set uploadTimer to now + 10 min
                reloadSummaryPeriodically(new Date(Date.now() + 10 * 60000), weekIndex);
            })
            .catch((error) => {
                console.error('Error in save and summarize sequence:', error);
                message.error('An error occurred while saving the course or generating the summary.');
                setSummaryIsBeingGenerated(prev => {
                    const newSummaryIsBeingGenerated = [...prev];
                    newSummaryIsBeingGenerated[weekIndex] = false;
                    return newSummaryIsBeingGenerated;
                });
            });

    }

    function toggleSetAssignment(weekIndex: number) {
        setCurrentWeekIndex(weekIndex);
        setShowAssignmentOverlay(prev => {
            const newShowAssignmentOverlay = [...prev];
            newShowAssignmentOverlay[weekIndex] = true;
            return newShowAssignmentOverlay;
        });
    }

    function handleAssignmentOverlayClose(weekIndex: number) {
        setShowAssignmentOverlay(prev => {
            const newShowAssignmentOverlay = [...prev];
            newShowAssignmentOverlay[weekIndex] = false;
            return newShowAssignmentOverlay;
        });

        if (!isEmptyOrOnlyTags(course?.weeks[weekIndex].assignment!)) {
            setAssignmentIsSet(prev => {
                const newAssignmentIsSet = [...prev];
                newAssignmentIsSet[weekIndex] = true;
                return newAssignmentIsSet;
            });
        }
    }

    function handleSetAssignment(weekIndex: number, value: string) {
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse;
            const newWeeks = [...prevCourse.weeks];
            const updatedWeek = {
                ...newWeeks[weekIndex],
                assignment: value
            };
            newWeeks[weekIndex] = updatedWeek;
            return {
                ...prevCourse,
                weeks: newWeeks
            };
        });
        setAssignmentIsSet(prev => {
            const newAssignmentIsSet = [...prev];
            newAssignmentIsSet[weekIndex] = !isEmptyOrOnlyTags(value);
            return newAssignmentIsSet;
        });
    }

    function handleModifyingSummary(weekIndex: number, value: string) {
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse;
            const newWeeks = [...prevCourse.weeks];
            const updatedWeek = {
                ...newWeeks[weekIndex],
                summary: value
            };
            newWeeks[weekIndex] = updatedWeek;
            return {
                ...prevCourse,
                weeks: newWeeks
            };
        });
        setSummaryIsSet(prev => {
            const newSummaryIsSet = [...prev];
            newSummaryIsSet[weekIndex] = !isEmptyOrOnlyTags(value);
            return newSummaryIsSet;
        });
        setSummaryIsBeingGenerated(prev => {
            const newSummaryIsBeingGenerated = [...prev];
            newSummaryIsBeingGenerated[weekIndex] = !isEmptyOrOnlyTags(value);
            return newSummaryIsBeingGenerated;
        });
    }

    function handleSetStartEndDate(weekIndex: number, dates: Dayjs[] | null) {
        if (!dates) return;
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse;
            const newWeeks = [...prevCourse.weeks];
            const updatedWeek = {
                ...newWeeks[weekIndex],
                start_date: dates[0].toISOString(),
                end_date: dates[1].toISOString()
            };
            newWeeks[weekIndex] = updatedWeek;
            return {
                ...prevCourse,
                weeks: newWeeks
            };
        });
        setTimeout(() => {
            setOpenDateRangeIndex(null);
        }, 0);
    }


    function handleOpenChangeDateRange(weekIndex: number, open: boolean) {
        if (!open) {
            // Get the latest course state
            setTempCourse(prevCourse => {
                if (!prevCourse) return prevCourse;
                const currentWeek = prevCourse.weeks[weekIndex];

                const hasValidDates = currentWeek?.start_date &&
                    currentWeek?.end_date &&
                    dayjs(currentWeek.start_date).isValid() &&
                    dayjs(currentWeek.end_date).isValid();

                if (!hasValidDates) {
                    message.error("Please select a date range for this module.");
                    setOpenDateRangeIndex(weekIndex); // Force picker to stay open
                    return prevCourse;
                }
                // Update open state only if validation passed
                setOpenDateRangeIndex(open ? weekIndex : null);
                return prevCourse;
            });
        } else {
            setOpenDateRangeIndex(weekIndex);
        }
    }


    function removeMaterialFromWeek(fileId: string, weekIndex: number) {
        setTempCourse(course => {
            const newWeeks = [...course!.weeks];
            const newMaterials = newWeeks[weekIndex].materials.filter(m => m.file_id !== fileId);
            newWeeks[weekIndex] = { ...newWeeks[weekIndex], materials: newMaterials };
            return { ...course!, weeks: newWeeks };
        })
    }


    return (
        <div>
            <h2>Modules</h2>
            <Collapse
                activeKey={activeKeys}
                onChange={(keys) => setActiveKeys(keys as string[])}
            >
                {course!.weeks.map((week, weekIndex) => (
                    <Panel
                        key={weekIndex}
                        header={
                            editingWeekTitle === weekIndex ? (
                                <Input
                                    value={week.title}
                                    onChange={(e) => handleWeekTitleChange(weekIndex, e.target.value)}
                                    onPressEnter={() => setEditingWeekTitle(null)}
                                    onBlur={() => setEditingWeekTitle(null)}
                                    onClick={(e) => e.stopPropagation()}
                                    autoFocus
                                />
                            ) : (
                                <Space
                                    data-testid={`week-title-${weekIndex}`}
                                >
                                    {`${week.title} | ${capitalize(user.config.module_terminology)} ${weekIndex + 1}` || `${capitalize(user.config.module_terminology)} ${weekIndex + 1}`}
                                    <EditOutlined onClick={(e) => {
                                        e.stopPropagation();
                                        setEditingWeekTitle(weekIndex);
                                    }} />
                                </Space>
                            )
                        }
                        collapsible={
                            editingWeekTitle === weekIndex
                                ? 'disabled'
                                : (!week.start_date || !week.end_date)
                                    ? 'disabled'
                                    : 'header'
                        }
                    >
                        <WeekContainer style={{ backgroundColor: hexToRGBA(token.colorBgElevated, 1) }}>
                            <h3>Duration</h3>
                            <Row>
                                <Tooltip title="The duration influences the analytics available to Atla and the module visibility for students.">
                                    <RangePicker
                                        data-testid={`range-picker-${weekIndex}`}
                                        value={week.start_date && week.end_date ?
                                            [dayjs(week.start_date), dayjs(week.end_date)] :
                                            null
                                        }
                                        onChange={(dates: any) => handleSetStartEndDate(weekIndex, dates as Dayjs[])}
                                        open={openDateRangeIndex === weekIndex}
                                        onOpenChange={(open: any) => handleOpenChangeDateRange(weekIndex, open)}
                                    />
                                </Tooltip>
                            </Row>
                            <h3>Contents</h3>
                            <Tabs
                                defaultActiveKey="1"
                                items={[
                                    {
                                        key: '1',
                                        label: <span><FileAddOutlined />Materials</span>,
                                        children: renderMaterialsTab(weekIndex, week),
                                    },
                                    {
                                        key: '2',
                                        label: <span><BarsOutlined />Topics</span>,
                                        children: renderTopicsTab(weekIndex),
                                    },
                                    {
                                        key: '3',
                                        label: <span><FileTextOutlined />Summary</span>,
                                        children: renderSummaryTab(weekIndex),
                                    },
                                    {
                                        key: '4',
                                        label: <span><SnippetsOutlined />Assignment</span>,
                                        children: renderAssignmentTab(weekIndex),
                                    },
                                ]}
                            />
                        </WeekContainer>
                    </Panel>
                ))}
            </Collapse>
            <Space style={{ marginTop: '1rem' }}>
                <FlexMarginButton
                    size="large"
                    icon={<PlusOutlined />}
                    onClick={() => createWeek()}>
                    {`Add ${capitalize(user.config.module_terminology)} ${course!.weeks.length + 1}`}
                </FlexMarginButton>
                {course!.weeks.length > 0 &&
                    <FlexMarginButton
                        size="large"
                        icon={<MinusOutlined />}
                        onClick={() => removeLastWeek()}>
                        {`Remove ${capitalize(user.config.module_terminology)} ${course!.weeks.length}`}
                    </FlexMarginButton>}
            </Space>
        </div>

    )
}