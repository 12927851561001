import React, { useState } from 'react';
import { Button, Dropdown, message, Modal, Table, Input } from 'antd';
import type { MenuProps } from 'antd';
import { LayoutOutlined, EditOutlined, DeleteOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import TemplateCreator from './template-creator';
import { Template, VariableDescription } from '../../services/templates';

interface TemplateMenuProps {
  onSetMessageText?: (text: string) => void;
  templates: Template[];
  onUpdateTemplates: (updatedTemplates: Template[]) => void;
  chatTypeSpecifier: string;
}

interface VariableWithDescription extends VariableDescription {
  value: string;
}

const TemplateMenu: React.FC<TemplateMenuProps> = ({
  onSetMessageText,
  templates,
  onUpdateTemplates,
  chatTypeSpecifier
}) => {
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
  const [isCreatorVisible, setCreatorVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [variableValues, setVariableValues] = useState<Record<string, VariableWithDescription>>({});

  const handleSave = (updatedTemplate: Template): boolean => {
    const existingTemplate = templates.find(template =>
      template.name.toLowerCase() === updatedTemplate.name.toLowerCase() &&
      template.ui_key !== updatedTemplate.ui_key
    );

    if (existingTemplate) {
      message.error('A template with this name already exists');
      return false;
    }

    let updatedTemplates;
    if (templates.some(template => template.ui_key === updatedTemplate.ui_key)) {
      updatedTemplates = templates.map(template =>
        template.ui_key === updatedTemplate.ui_key ? updatedTemplate : template
      );
    } else {
      updatedTemplates = [...templates, updatedTemplate];
    }

    onUpdateTemplates(updatedTemplates);
    message.success('Template saved successfully');
    return true;
  };

  const handleDelete = (template: Template) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this template?',
      content: 'This action cannot be undone',
      onOk: () => {
        const updatedTemplates = templates.filter(t => t.ui_key !== template.ui_key);
        onUpdateTemplates(updatedTemplates);
        message.success('Template deleted successfully');
      }
    });
  };

  const handleEdit = (template: Template) => {
    setCurrentTemplate(template);
    setCreatorVisible(true);
  };

  const handleVariableChange = (variable: string, value: string) => {
    setVariableValues(prev => ({ ...prev, [variable]: { ...prev[variable], value } }));
  };

  const prepareTemplateText = (template: Template) => {
    const dataSource = template.variables.map(item => ({
      key: item.variable,
      variable: item.variable,
      value: variableValues[item.variable]?.value || '',
      placeholder: item.description || '',
    })) || [];

    let newText = template.text ?? '';
    dataSource.forEach(data => {
      const value = data.value || '';
      newText = newText.replace(new RegExp(`{${data.variable}}`, 'g'), value);
    });

    return newText;
  };

  const handleTemplateSelect = (template: Template) => {
    setCurrentTemplate(template);

    if (!template.variables || template.variables.length === 0) {
      const newText = prepareTemplateText(template);
      onSetMessageText?.(newText);
    } else {
      const resetValues = template.variables.reduce((acc, variable) => {
        acc[variable.variable] = { ...variable, value: '' };
        return acc;
      }, {} as Record<string, VariableWithDescription>);
      setVariableValues(resetValues);
      setOverlayVisible(true);
    }
  };

  const handleVariableSubmit = () => {
    if (currentTemplate) {
      const newText = prepareTemplateText(currentTemplate);
      onSetMessageText?.(newText);
    }
    setOverlayVisible(false);
  };

  const renderVariableOverlay = () => {
    const dataSource = currentTemplate?.variables.map(item => ({
      key: item.variable,
      variable: item.variable,
      value: variableValues[item.variable]?.value || '',
      placeholder: item.description || '',
    })) || [];

    const columns = [
      {
        title: 'Variable',
        dataIndex: 'variable',
        key: 'variable',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        render: (_: string, record: any) => (
          <Input
            value={record.value}
            placeholder={record.placeholder}
            onChange={(e) => handleVariableChange(record.variable, e.target.value)}
          />
        ),
      },
    ];

    return (
      <Modal
        title="Set Variable Values"
        open={overlayVisible}
        onOk={handleVariableSubmit}
        onCancel={() => setOverlayVisible(false)}
        style={{
          minHeight: '80%',
          maxHeight: '80%',
          minWidth: '50%',
          maxWidth: '50%',
          margin: '0 auto',
          top: '17vh'
        }}
      >
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </Modal>
    );
  };

  const items: MenuProps['items'] = [
    {
      key: 'new',
      icon: <PlusOutlined />,
      label: 'Create New Template',
      onClick: () => {
        setCurrentTemplate(null);
        setCreatorVisible(true);
      }
    },
    ...(templates.length > 0 ? [{ type: 'divider' as const }] : []),
    ...templates.map((template) => ({
      key: template.ui_key,
      label: template.name,
      children: [
        {
          key: `use-${template.ui_key}`,
          icon: <RightOutlined />,
          label: 'Use',
          onClick: () => handleTemplateSelect(template)
        },
        {
          key: `edit-${template.ui_key}`,
          icon: <EditOutlined />,
          label: 'Edit',
          onClick: () => handleEdit(template)
        },
        {
          key: `delete-${template.ui_key}`,
          icon: <DeleteOutlined />,
          label: 'Delete',
          onClick: () => handleDelete(template),
          danger: true
        }
      ]
    }))
  ];

  return (
    <>
      <Dropdown
        menu={{ items }}
        trigger={['hover']}
      >
        <Button
          icon={<LayoutOutlined />}
          data-testid="template-menu-button"
        />
      </Dropdown>

      <TemplateCreator
        visible={isCreatorVisible}
        onClose={() => {
          setCreatorVisible(false);
          setCurrentTemplate(null);
        }}
        onSave={handleSave}
        template={currentTemplate}
        type={chatTypeSpecifier}
      />

      {renderVariableOverlay()}
    </>
  );
};

export default TemplateMenu;