import { CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined, SyncOutlined } from "@ant-design/icons";
import { Spacer } from "@nextui-org/react";
import { styled } from "@stitches/react";
import { Button, Modal, Table, Typography, Upload, UploadFile, UploadProps, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../screens/courses/course";
import { CourseFile, deleteCourseFile, extractAudioAndConvertToAudioFile } from "../../services/courses";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { formatDate } from "../../utils/utils";
import React from "react";
import { supportedFormats } from "../../config";
import { FlexMarginButton } from "../basic/buttons";
import authedAxios from "../../services/auth-axios";
import FileSpecificationOverlay, { FileSpecification } from './FileSpecificationOverlay';
import { analyzeFile } from "./file-analysis";

const { confirm } = Modal;
const { Dragger } = Upload;

const StyledDragger = styled(Dragger, {
    '& .ant-upload-drag': {
        height: 'auto'
    }
})

interface UploadCallbacks {
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
    onProgress?: (event: any) => void;
}

interface FileMetadata {
    fileType: 'document' | 'media' | 'other';
    pages?: number;
    duration?: number;
}

interface FileWithMetadata {
    file: UploadFile;
    metadata: FileMetadata;
    callbacks: UploadCallbacks;
}

export const Materials = () => {
    const user = getUserDataFromLocalStorage();
    const { tempCourse: course, courseFiles, setCourseFiles, fetchCourseFiles, checkFileStatusAfterUpload } = useContext(CourseContext)
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
    const [uploadTimer, setUploadTimer] = useState(new Date());
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);
    const [showSpecOverlay, setShowSpecOverlay] = useState(false);
    const [fileQueue, setFileQueue] = useState<FileWithMetadata[]>([]);
    const [currentFileIndex, setCurrentFileIndex] = useState<number>(-1);


    useEffect(() => {
        if (uploadTimer.getTime() > Date.now()) {
            checkFileStatusAfterUpload(uploadTimer);
        }
    }, [uploadTimer]);

    useEffect(() => {
        if (fileQueue.length > 0 && currentFileIndex === -1) {
            setCurrentFileIndex(0);
            setShowSpecOverlay(true);
        }
    }, [fileQueue, currentFileIndex]);

    useEffect(() => {
        fetchCourseFiles(course!._id);
    }, []);

    const processNextFile = () => {
        if (currentFileIndex < fileQueue.length - 1) {
            setCurrentFileIndex(prev => prev + 1);
            setShowSpecOverlay(true);
        } else {
            // Reset queue when all files are processed
            setFileQueue([]);
            setCurrentFileIndex(-1);
            setShowSpecOverlay(false);
        }
    };

    const getCurrentFile = (): FileWithMetadata | null => {
        if (currentFileIndex === -1 || !fileQueue.length) return null;
        return fileQueue[currentFileIndex];
    };


    const uploadFile = async (file: UploadFile, fileSpecs?: FileSpecification) => {
        const formData = new FormData();
        formData.append('course_files', file as any);

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    userId: user?._id || '',
                    courseId: course!._id || '',
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    ...(fileSpecs && { 'fileSpecifications': JSON.stringify(fileSpecs) })
                },
                onUploadProgress: (progressEvent: any) => {
                    const currentFile = getCurrentFile();
                    if (progressEvent.total && currentFile?.callbacks.onProgress) {
                        const percent = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        currentFile.callbacks.onProgress({ percent });
                    }
                }
            };

            const response = await authedAxios.post('/upload', formData, config);
            const currentFile = getCurrentFile();
            currentFile?.callbacks.onSuccess?.(response.data);
            message.success(`File uploaded successfully!`);
            setUploadTimer(new Date(Date.now() + 30 * 60000));

        } catch (error) {
            const currentFile = getCurrentFile();
            currentFile?.callbacks.onError?.(error);
            message.error('File upload failed!');
        }
    };
    
    const handleSpecificationSubmit = (specs: FileSpecification) => {
        const currentFile = getCurrentFile();
        if (!currentFile) return;

        if (specs.startPage && specs.endPage && specs.startPage >= specs.endPage) {
            message.error('Invalid specifications!');
            return;
        }

        uploadFile(currentFile.file, specs);
        processNextFile();
    };

    const handleSpecificationSkip = () => {
        const currentFile = getCurrentFile();
        if (!currentFile) return;

        uploadFile(currentFile.file);
        processNextFile();
    };

    const handleDeleteFile = (filename: string, fileId: string) => {
        confirm({
            title: React.createElement('span', null, `Are you sure you want to delete the file ${filename}?`),
            icon: <ExclamationCircleOutlined />,
            content: 'This operation cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleConfirmDelete(fileId);
            },
        });
    };

    const handleConfirmDelete = async (fileId: string) => {
        setLoading(true);
        try {
            await deleteCourseFile(fileId, course!._id);
            message.success('File deleted successfully.', 6);
            setCourseFiles((prevRows) => prevRows.filter((row: any) => row._id !== fileId));
        } catch (error) {
            message.error('Error deleting file.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteSelected = () => {
        confirm({
            title: 'Are you sure you want to delete the selected files?',
            icon: <ExclamationCircleOutlined />,
            content: 'This operation cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleConfirmDeleteSelected();
            },
        });
    };

    const handleConfirmDeleteSelected = async () => {
        setLoading(true);
        try {
            const deletePromises = selectedRowKeys.map(fileId =>
                deleteCourseFile(fileId.toString(), course!._id)
            );
            await Promise.all(deletePromises);
            message.success('Files deleted successfully.', 6);
            setCourseFiles((prevRows) =>
                prevRows.filter((row: any) => !selectedRowKeys.includes(row._id))
            );
            setSelectedRowKeys([]);
        } catch (error) {
            message.error('Error deleting files.');
        } finally {
            setLoading(false);
        }
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleVideoFileBeforeUpload = async (file: UploadFile): Promise<boolean | Promise<UploadFile>> => {
        if (file.type === 'video/mp4') {
            try {
                // Extract audio from the video file
                const audioFile = await extractAudioAndConvertToAudioFile(file);
                // Return a promise that resolves to the audio file
                return audioFile;
            } catch (error) {
                // Handle the error appropriately, for example by showing a message
                message.error('Failed to extract audio from the video file.');
                return false; // Return false to stop the upload
            }
        }
        // If not a video, return the original file
        return file;
    };

    const uploadProps: UploadProps = {
        action: `${authedAxios.defaults.baseURL}/upload`,
        onChange(info: any) {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`File uploaded successfully!`);
                setUploadTimer(new Date(Date.now() + 30 * 60000));
            } else if (status === 'error') {
                let errorMessage = 'File upload failed!';
                if (info.file.response && info.file.response.detail) {
                    errorMessage += ' ' + info.file.response.detail;
                }
                message.error(errorMessage);
            }
            setTimeout(() => { setFileList([]); }, 10000);
            let newFileList: UploadFile<any>[] = [...info.fileList];
            setFileList(newFileList as UploadFile<any>[]);
        },
        fileList: fileList,
        headers: {
            userid: user?._id || '',
            courseid: course!._id || '',
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        name: 'course_files',
        multiple: true,
        customRequest: async ({ file, onSuccess, onError, onProgress }) => {
            const callbacks = { onSuccess, onError, onProgress };

            try {
                const analysis = await analyzeFile(file as File);

                if (analysis.requiresSpecification) {
                    setFileQueue(prev => [...prev, {
                        file: file as UploadFile,
                        metadata: {
                            fileType: analysis.type,
                            pages: analysis.pages,
                            duration: analysis.duration
                        },
                        callbacks
                    }]);
                    return;
                }

                await uploadFile(file as UploadFile);
            } catch (error) {
                onError?.(error as any);
                message.error('Error processing file');
            }
        },
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                // Check if the file already exists in the fileList
                const fileExists = courseFiles.some(existingFile => existingFile.file_name === file.name);
                if (fileExists) {
                    message.error('File already exists!');
                    reject();
                    return;
                }
                const fileFormat = `.${file.name.split('.').pop()?.toLowerCase()}`;
                if (!fileFormat || !supportedFormats.includes(fileFormat)) {
                    message.error('Unsupported file format!');
                    reject();
                    return;
                }

                handleVideoFileBeforeUpload(file).then((result) => {
                    resolve(result as any);
                });
            });
        },
    };

    return (<>
        <h2>Materials</h2>
        <StyledDragger {...uploadProps} data-testid='file-upload'>
            <p className="ant-upload-drag-icon" data-testid='file-upload-icon'>
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
                Supported formats: {supportedFormats.join(', ').toUpperCase()}. <br />
                All video formats will be converted to WAV. <br />
                The formats DOC and ODT will be converted to PDF.
            </p>
        </StyledDragger>
        <FileSpecificationOverlay
            file={getCurrentFile()?.file!}
            metadata={getCurrentFile()?.metadata!}
            visible={showSpecOverlay}
            existingFiles={courseFiles}  // Add this prop
            onCancel={() => {
                const currentFile = getCurrentFile();
                if (currentFile) {
                    currentFile.callbacks.onError?.(new Error('Upload cancelled by user'));
                }
                setShowSpecOverlay(false);
                setFileQueue([]);
                setCurrentFileIndex(-1);
            }}
            onSubmit={handleSpecificationSubmit}
            onSkip={handleSpecificationSkip}
        />

        <Spacer y={10} />
        <Typography.Paragraph>
            Uploaded Files will appear in the table below. Processing of large files can take up to an hour, but you can already start using files in processing for the course specifications.
        </Typography.Paragraph>
        <Typography.Paragraph>
            Should the upload not be successful, please inform via the quick support button the development team to sort it out quickly!
        </Typography.Paragraph>
        <>
            <Table
                loading={loading}
                rowSelection={rowSelection}
                dataSource={courseFiles}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'file_name',
                        key: 'file_name',
                        width: '40%',
                    },
                    {
                        title: 'Uploaded at',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        render: (text: string) => {
                            return formatDate(text);
                        },
                        width: '40%',
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        align: 'center',
                        render: (status: string) => {
                            if (status === 'available') {
                                return <CheckCircleOutlined style={{ color: 'green' }} />;
                            } else if (status === 'unavailable') {
                                return <ExclamationCircleOutlined style={{ color: 'orange' }} />;
                            } else {
                                return <SyncOutlined spin />;
                            }
                        },
                        width: '10%',
                    },
                    {
                        title: 'Delete',
                        dataIndex: 'delete',
                        key: 'delete',
                        align: 'center',
                        render: (_: any, record: CourseFile) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FlexMarginButton
                                    data-testid="delete-button"
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        handleDeleteFile(record.file_name, record._id)
                                    }}
                                />
                            </div>
                        ),
                        width: '10%',
                    },
                ]}
                rowKey={record => record._id}
                pagination={{
                    defaultPageSize: 4,
                }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <Button
                    type="primary"
                    danger
                    onClick={handleDeleteSelected}
                    disabled={!selectedRowKeys.length}
                >
                    Delete Selected Files
                </Button>
            </div>
        </>
    </>);
};