import { styled } from "@stitches/react";
import { Avatar, Button, Col, Divider, Dropdown, Layout, MenuProps, message, Popconfirm, Popover, Row, Typography } from "antd";
import { createContext, useEffect, useState } from "react";
import { Materials } from "../../components/course/materials";
import { Weeks } from "../../components/course/weeks";
import { useCourse } from "../../hooks/useCourse";
import { Course, CourseFile, UserOverview } from "../../services/courses";
import { capitalize, getRandomDimColor } from "../../utils/utils";
import StudentTable from "../../components/course/student-table";
import { FlexMarginButton, SaveButton } from "../../components/basic/buttons";
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from "../../utils/useLocalStorage";
import Icon, { DownOutlined } from "@ant-design/icons";
import TemplateCreator from "../../components/chat/template-creator";
import { Spacer } from "@nextui-org/react";
import TemplateTable from "../../components/course/template-table";
import { Template } from "../../services/templates";
import { chatFormatContent } from "../../config";
import { useUnsavedChanges } from "../../contexts/UnsavedChangesContext";


const GBflag = () => (
    <img
        alt="United States"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg" />
);
const FRflag = () => (
    <img
        alt="United States"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg" />
);
const DEflag = () => (
    <img
        alt="United States"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg" />
);
const ESflag = () => (
    <img
        alt="United States"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg" />
);

const { Content } = Layout;

const DeleteButton = styled(Button, {
    background: "red !important",
    borderColor: "darkred !important",
    color: "white !important",
    '&:hover': {
        background: "darkred !important",
        borderColor: "red !important",
        color: "white !important",
    }
});



type CourseContextType = {
    tempCourse: Course | undefined;
    setTempCourse: React.Dispatch<React.SetStateAction<Course | undefined>>;
    saveCourseChanges: () => Promise<void>;
    deleteCurrentCourse: () => Promise<void>;
    courseSavingLoading: boolean;
    hasChanges: boolean;
    courseFiles: CourseFile[];
    setCourseFiles: React.Dispatch<React.SetStateAction<CourseFile[]>>;
    fetchCourseFiles: (courseId: string) => Promise<void>;
    checkFileStatusAfterUpload: (uploadTimer: Date) => Promise<() => void>;
    reloadSummaryPeriodically: (uploadTimer: Date, weekIndex: number) => Promise<() => void>;
};

export const CourseContext = createContext<CourseContextType>({
    tempCourse: undefined,
    setTempCourse: () => { },
    saveCourseChanges: () => Promise.resolve(),
    deleteCurrentCourse: () => Promise.resolve(),
    courseSavingLoading: false,
    hasChanges: false,
    courseFiles: [],
    setCourseFiles: () => { },
    fetchCourseFiles: () => Promise.resolve(),
    checkFileStatusAfterUpload: () => Promise.resolve(() => { }),
    reloadSummaryPeriodically: () => Promise.resolve(() => { })
});

const CoursePage: React.FC = () => {
    const courseHook = useCourse()
    const { setHasUnsavedChanges } = useUnsavedChanges();

    const { tempCourse, setTempCourse, saveCourseChanges, deleteCurrentCourse, courseSavingLoading, hasChanges } = courseHook
    const user = getUserDataFromLocalStorage();
    const [isTemplateModalVisible, setTemplateModalVisible] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState<Template | null>(null);

    useEffect(() => {
        setHasUnsavedChanges(hasChanges);
    }, [hasChanges, setHasUnsavedChanges]);

    const handleStudentEnroll = (student: UserOverview) => {
        setTempCourse(prevCourse => {
            if (prevCourse) {
                const updatedStudents = [...prevCourse.enrolled_students, student]
                    .sort((a, b) => a.name.localeCompare(b.name));
                return { ...prevCourse, enrolled_students: updatedStudents };
            }
            return prevCourse;
        });
    };

    const handleStudentUnenroll = (student: UserOverview) => {
        setTempCourse(prevCourse => {
            if (prevCourse) {
                const updatedStudents = prevCourse.enrolled_students
                    .filter(s => s._id !== student._id)
                    .sort((a, b) => a.name.localeCompare(b.name));
                return { ...prevCourse, enrolled_students: updatedStudents };
            }
            return prevCourse;
        });
    };

    const handlelanguageMenuClick: MenuProps['onClick'] = (language_event) => {
        setTempCourse(prevCourse => {
            if (prevCourse) {
                return { ...prevCourse, language: language_event.key };
            }
            return prevCourse;
        });
    };

    const languageItems: MenuProps['items'] = [
        {
            label: 'English',
            key: 'EN',
            icon: <Icon component={GBflag} />,
        },
        {
            label: 'French',
            key: 'FR',
            icon: <Icon component={FRflag} />,
        },
        {
            label: 'German',
            key: 'DE',
            icon: <Icon component={DEflag} />,
        },
        {
            label: 'Spanish',
            key: 'ES',
            icon: <Icon component={ESflag} />,
        },
    ];

    const languageMenuProps = {
        items: languageItems,
        onClick: handlelanguageMenuClick,
    };

    const handleDeleteCourse = async () => {
        if (tempCourse) {
            await deleteCurrentCourse();

            // Update user's course_id_list
            const updatedUser = {
                ...user,
                course_id_list: user.course_id_list.filter((id: string) => id !== tempCourse._id)
            };

            // Save updated user to localStorage
            setUserDataInLocalStorage(updatedUser);
        }
    };

    const handleAddTemplate = (newTemplate: Template): boolean => {
        if (editingTemplate) {
            setTempCourse(prevCourse => {
                if (prevCourse) {
                    const updatedTemplates = prevCourse.templates.map(template =>
                        template.ui_key === editingTemplate.ui_key ? newTemplate : template
                    );
                    return { ...prevCourse, templates: updatedTemplates };
                }
                return prevCourse;
            });
            message.success('Template updated successfully');
            return true;
        } else {
            const templateExists = tempCourse?.templates.some(template =>
                template.name.toLowerCase() === newTemplate.name.toLowerCase() ||
                template.ui_key === newTemplate.ui_key
            );
            if (!templateExists) {
                setTempCourse(prevCourse => {
                    if (prevCourse) {
                        const updatedTemplates = [...prevCourse.templates, newTemplate];
                        return { ...prevCourse, templates: updatedTemplates };
                    }
                    return prevCourse;
                });
                message.success('Template saved successfully');
                return true;
            } else {
                message.error('A template with this name already exists');
                return false;
            }
        }
    };

    const handleEditTemplate = (template: Template | null) => {
        setEditingTemplate(template);
        setTemplateModalVisible(true);
    };

    const handleDeleteTemplate = (templateKey: string) => {
        setTempCourse(prevCourse => {
            if (prevCourse) {
                const updatedTemplates = prevCourse.templates.filter(t => t.ui_key !== templateKey);
                return { ...prevCourse, templates: updatedTemplates };
            }
            return prevCourse;
        });
    };

    const handleTemplateModalClose = () => {
        setTemplateModalVisible(false);
        setEditingTemplate(null);
    };

    return (
        <Content style={{ padding: "2rem" }}>
            {tempCourse && <>
                {hasChanges && <SaveButton loading={courseSavingLoading} type="primary" size="large" onClick={saveCourseChanges} >Save Changes</SaveButton>}
                <Typography.Title editable={{
                    onChange: name => {
                        //@ts-ignore
                        setTempCourse(p => ({ ...p, name }))
                    }
                }} level={1}>
                    {tempCourse.name}
                </Typography.Title>
                <Avatar.Group>
                    {tempCourse.professors.map((professor) => (
                        <Popover placement="right" title={"Prof. " + professor.name}>
                            <Avatar size="large" src={professor.photo_url} style={{ background: getRandomDimColor() }} >{professor.name[0]}</Avatar>
                        </Popover>
                    ))}
                </Avatar.Group>
                <Typography.Paragraph editable={{
                    onChange: description => {
                        //@ts-ignore
                        setTempCourse(p => ({ ...p, description }))
                    }
                }} >
                    {tempCourse.description}
                </Typography.Paragraph>
                <Row align="middle" gutter={32}>
                    <Col span={3}>
                        <Typography.Paragraph style={{ margin: 0 }}>
                            Content Language:
                        </Typography.Paragraph>
                    </Col>
                    <Col span={29}>
                        <Dropdown menu={languageMenuProps}>
                            <FlexMarginButton>
                                {tempCourse.language || 'Choose language'}
                                <DownOutlined />
                            </FlexMarginButton>
                        </Dropdown>
                    </Col>
                </Row>
                <CourseContext.Provider value={courseHook}>
                    <Divider
                        orientation="right"
                        orientationMargin={50}>
                    </Divider>
                    <StudentTable
                        assigned_students={tempCourse.assigned_students}
                        enrolledStudents={tempCourse?.enrolled_students || []}
                        onStudentEnroll={handleStudentEnroll}
                        onStudentUnenroll={handleStudentUnenroll}
                    />
                    <Divider
                        orientation="right"
                        orientationMargin={50}>
                    </Divider>
                    <Materials />

                    <Divider
                        orientation="right"
                        orientationMargin={50}>
                    </Divider>

                    <Weeks />
                </CourseContext.Provider>
                <Divider
                    orientation="right"
                    orientationMargin={50}>
                </Divider>
                <div>
                    <Typography.Title level={3}>Templates</Typography.Title>
                    <Typography.Paragraph>
                        This section is optional. Templates added to the course will be made available to each student chatting with the course.
                    </Typography.Paragraph>
                    <FlexMarginButton onClick={() => handleEditTemplate(null)}
                    >
                        + New Template
                    </FlexMarginButton>
                    <Spacer y={2} />
                    <TemplateTable
                        templates={tempCourse?.templates}
                        handleEditTemplate={handleEditTemplate}
                        handleDeleteTemplate={handleDeleteTemplate}
                    />
                </div>
                <TemplateCreator
                    visible={isTemplateModalVisible}
                    onClose={handleTemplateModalClose}
                    onSave={handleAddTemplate}
                    template={editingTemplate}
                    type={chatFormatContent}
                />
                <Divider
                    orientation="right"
                    orientationMargin={50}>
                </Divider>
                <Popconfirm
                    title={`Delete the ${user.config.course_terminology.toLowerCase()}`}
                    description={<p>{`Are you sure to delete this ${user.config.course_terminology.toLowerCase()}`}<br />This action is irreversible</p>}
                    onConfirm={handleDeleteCourse}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteButton
                        type="default"
                        data-testid="delete-course-button"
                    >
                        {`Delete ${capitalize(user.config.course_terminology)}`}
                    </DeleteButton>
                </Popconfirm>
            </>}

        </Content>
    )
}

export default CoursePage;
