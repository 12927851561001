import React from 'react';
import { Typography } from 'antd';
import ReactMarkdown from 'react-markdown';
import { changelogContent } from '../changelog';
import { Content } from 'antd/es/layout/layout';


const { Title } = Typography;

interface ChangelogProps {}

const Changelog: React.FC<ChangelogProps> = () => {
    return (
        <Content style={{ padding: "2rem" }}>
            <Title level={1}>Changelog</Title>
            <ReactMarkdown>{changelogContent}</ReactMarkdown>
        </Content>
    );
};

export default Changelog;