import { theme, ThemeConfig } from "antd";
import { hexToRGBA } from "./utils/utils";
import "@fontsource/inter";

export const portraitViewThreshold = 1024;
export const siderWidthOpened = "16vw";

export const autodidact_options = [
    { title: 'Pay as you go', number: -1 },
    { title: '300', number: 300 },
    { title: '500', number: 500 }
];

export const chatFormatContent = 'content'
export const chatFormatAnalytics = 'analytics'

export const sona_logo_path = '/sona_logo_192x192.png' 
export const atla_logo_path = '/atla_logo_192x192.png'

export const page_threshold = 50;  // Maximum number of pages before showing overlay
export const duration_threshold = 600;  // Maximum duration in seconds
    

export const supportedFormats = [
    '.pdf', // pdf
    '.mp3', '.wav', '.mp2', '.mp1', '.m4a', '.ogg', '.flac', '.wma', '.aiff', // audio
    '.mp4', // video
    '.docx', // ms document
    '.txt', // text
    '.pptx', '.ppt', // ms presentation
    '.odt', // open document text
    '.vtt', // webvtt
];

export const noPageFormats = [
    '.txt', // text
];

export const audioFormats = [
    '.wav', '.mp3', '.m4a', '.mp2', '.mp1', '.ogg', '.flac', '.wma', '.aiff', // audio
    '.mp4', // video,
    '.vtt', // webvtt
];

export const dark_theme_token = {
    colorText: '#fff',

    colorBgContainer: '#141414',
    colorBgElevated: '#424242',
    colorBgSpotlight: '#002654',

    colorLink: '#1890ff',

    colorPrimary: '#1890ff',
    colorPrimaryActive: '#1890ff',
    colorPrimaryHover: '#FAC748',
    colorPrimaryBgHover: '#FAC748',

    colorPrimaryBorder: '#272727',
    colorPrimaryBorderHover: '#303030',

    colorPrimaryText: '#ffffff',
    colorPrimaryTextActive: '#e6f7ff',
    colorPrimaryTextHover: '#bae7ff',

    colorInfoText: '#FAFAFA',
    colorTextPlaceholder: hexToRGBA('#FAFAFA', 0.4),

    fontFamily: "Inter"
};
export const default_theme_token = {
    colorText: '#000000',

    colorBgContainer: '#F3F3F3',
    colorBgElevated: '#F0F0F0',
    colorBgSpotlight: '#002654',

    colorLink: '#002654',

    colorPrimary: '#1890ff',
    colorPrimaryActive: '#1890ff',
    colorPrimaryHover: '#002654',
    colorPrimaryBgHover: '#1890ff',

    colorPrimaryBorder: '#002654',
    colorPrimaryBorderHover: '#FAFAFA',

    colorPrimaryText: '#000000',
    colorPrimaryTextActive: '#141414',
    colorPrimaryTextHover: '#141414',

    colorInfoText: '#141414',
    colorTextPlaceholder: hexToRGBA('#141414', 0.4),

    fontFamily: "Inter"
};

export const dark_theme_components = {
    Layout: {
        triggerBg: '#141414',
        triggerColor: '#ffffff',
        bodyBg: '#2a2a2a',
        siderBg: '#141414',
    },
    Menu: {
        colorItemBgHover: hexToRGBA('#ffffff', 0.2),
        colorItemBgSelected: "#000000",
        colorItemTextSelected: "#ffffff",
    },
    Divider: {
        orientation: "right",
        orientationMargin: 50,
    }
};

export const default_theme_components = {
    Layout: {
        triggerBg: hexToRGBA('#F3F3F3', 0.5),
        triggerColor: '#000000',
        bodyBg: hexToRGBA('#F3F3F3', 0.5),
        siderBg: '#F3F3F3',
    },
    Menu: {
        colorItemBgHover: hexToRGBA('#FAC748', 0.5),
        // colorSubItemBg: hexToRGBA('#1890ff', 0.2),
        colorItemBgSelected: '#FAC748', //hexToRGBA('#1890ff', 0.4),
        colorItemTextSelected: "#002654",
    },
    Divider: {
        orientation: "right",
        orientationMargin: 50,
        colorSplit: '#002654'
    }
};

export const dark_theme_config: ThemeConfig = {
    algorithm: theme.darkAlgorithm,
    token: dark_theme_token,
    components: dark_theme_components,
};

export const default_theme_config: ThemeConfig = {
    algorithm: theme.defaultAlgorithm,
    token: default_theme_token,
    components: default_theme_components,
};

export const colorSona: string = '#F08094';
export const colorAtla: string = '#61A8EB';