import {
    FolderOutlined, HistoryOutlined, LeftOutlined, LockOutlined, QuestionCircleOutlined,
    RightOutlined, UserOutlined, SunOutlined, SunFilled, FormOutlined, ContactsOutlined, ArrowsAltOutlined,
    DollarOutlined,
    SettingOutlined,
    ShareAltOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import { styled } from '@stitches/react';
import { Layout, Menu, message } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QuickSupportModal from './quick-support-modal';
import { getUserDataFromLocalStorage } from '../utils/useLocalStorage';
import { capitalize } from '../utils/utils';
import { siderWidthOpened } from '../config';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';
import NavigationGuard from './NavigationGuard';

const { Sider } = Layout;
const collapsedSiderWidth = 100;

const TopContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '3rem'
})
const LogoContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})
const Logo = styled('img', {
    height: "80px",
    padding: '1rem',
})

const StyledMenu = styled(Menu, {
    width: '100%',
    '& .ant-menu-item:last-child': {
        marginTop: 'auto',
    },
})

const StyledSider = styled(Sider, {
    "& > .ant-layout-sider-children": {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    height: '100vh',
    overflow: 'auto',
    position: 'fixed !important',
    left: 0,
    top: 0,
    bottom: 0,
})

export default function Sidebar({ onLogout, children, isDarkMode, toggleTheme, isDesktopPortraitView }: {
    onLogout: () => void;
    children: React.ReactNode;
    isDarkMode: boolean;
    toggleTheme: () => void;
    isDesktopPortraitView: boolean;
}) {
    const navigate = useNavigate();
    const [targetPath, setTargetPath] = React.useState<string | null>(null);
    const { hasUnsavedChanges } = useUnsavedChanges();
    const [logoData, setLogoData] = React.useState<string | undefined>(undefined);
    const [headerCollapsed, setHeaderCollapsed] = React.useState<boolean>(false);
    const [quickSupportModalOpen, setQuickSupportModalOpen] = React.useState(false);

    const location = useLocation();
    const currentPath = location.pathname;
    const activeTab = `/${currentPath.split("/")[1]}`;
    const user = getUserDataFromLocalStorage();
    const referralLink = `${window.location.origin}/signup?referral_code=${user.referrals.code}`;

    useEffect(() => {
        if (isDesktopPortraitView) {
            setHeaderCollapsed(true);
        } else {
            setHeaderCollapsed(false);
        }
    }, [isDesktopPortraitView]);

    const handleMenuClick = (path: string) => {
        if (hasUnsavedChanges && path !== location.pathname) {
            setTargetPath(path);
        } else {
            navigate(path);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            message.success('Referral link copied to clipboard!');
        }).catch(err => {
            message.error('Failed to copy referral link.');
        });
    };

    function getItems() {
        if (["technical"].includes(user.type)) {
            return [
                { key: "/courses", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => handleMenuClick("/courses") },
            ]
        }
        if (["student", "autodidact"].includes(user.type)) {
            return [
                { key: "/courses", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => handleMenuClick("/courses") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => handleMenuClick("/chat-history") },
            ]
        }
        if (user.type === "professor") {
            return [
                { key: "/courses", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => handleMenuClick("/courses") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => handleMenuClick("/chat-history") },
                { key: "/assigned-students ", icon: <ContactsOutlined />, label: "Assigned Students", onClick: () => handleMenuClick("/assigned-students") },
            ]
        }
        if (['admin', 'technical_admin'].includes(user.type)) {
            return [
                { key: "/courses", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => handleMenuClick("/courses") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => handleMenuClick("/chat-history") },
                { key: "/assigned-students", icon: <ContactsOutlined />, label: "Assigned Students", onClick: () => handleMenuClick("/assigned-students") },
                { key: "/administration", icon: <FormOutlined />, label: "Administration", onClick: () => handleMenuClick("/administration") }
            ]
        }
        else {
            return []
        }
    }

    useEffect(() => {
        setLogoData(user.config.logo)
    }, [user.config.logo])

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {targetPath && (
                <NavigationGuard
                    onNavigate={() => setTargetPath(null)}
                    targetPath={targetPath}
                />
            )}
            <StyledSider
                trigger={null}
                collapsible
                collapsed={headerCollapsed}
                onCollapse={(value) => setHeaderCollapsed(value)}
                width={siderWidthOpened}
                collapsedWidth={collapsedSiderWidth}
            >
                <div>
                    <TopContainer>
                        <LogoContainer><Logo
                            src={logoData}
                            alt={user.config.organization} />
                        </LogoContainer>
                    </TopContainer>
                    <StyledMenu
                        selectedKeys={[activeTab]}
                        mode="vertical"
                        items={getItems()}
                    />
                </div>
                <StyledMenu
                    selectedKeys={[activeTab]}
                    mode="vertical"
                    items={[
                        ...(user.type !== 'technical' ? [{
                            key: "templates",
                            icon: <ArrowsAltOutlined />,
                            label: "Templates",
                            onClick: () => handleMenuClick("/users")
                        },] : []),
                        {
                            key: "quick-support",
                            icon: <QuestionCircleOutlined
                                twoToneColor="blue" />,
                            label: "Get Quick Support",
                            onClick: () => setQuickSupportModalOpen(true)
                        },
                        {
                            key: "/profile",
                            icon: <UserOutlined />,
                            label: "Profile",
                            children: [
                                ...(user.type !== 'student' && user.type !== 'technical' ? [{
                                    key: "/subscription",
                                    icon: <DollarOutlined />,
                                    label: "Subscription",
                                    onClick: () => handleMenuClick("/subscription")
                                }] : []),
                                ...(user.type !== 'technical' ? [{
                                    key: "/settings",
                                    icon: <SettingOutlined />,
                                    label: "Settings",
                                    onClick: () => handleMenuClick("/settings")
                                }] : []),
                                {
                                    key: "/changelog",
                                    icon: <FileTextOutlined />,
                                    label: "Updates",
                                    onClick: () => handleMenuClick("/changelog")
                                },
                                {
                                    key: "/logout",
                                    icon: <LockOutlined />,
                                    label: "Log out",
                                    onClick: onLogout
                                },
                            ]
                        },
                        {
                            key: "share",
                            icon: <ShareAltOutlined />,
                            label: "Refer a Friend",
                            onClick: copyToClipboard
                        },
                        {
                            key: "theme_toggle",
                            icon: isDarkMode ? <SunOutlined style={{ color: 'white' }} /> : <SunFilled style={{ color: 'black' }} />,
                            label: "Switch Theme",
                            onClick: () => toggleTheme()
                        },
                        {
                            key: "collapse",
                            icon: headerCollapsed ? <RightOutlined /> : <LeftOutlined />,
                            onClick: () => setHeaderCollapsed(prev => !prev),
                            label: null,
                            title: "",
                            style: { textAlign: 'center' }
                        },
                    ]} />
            </StyledSider>
            <Layout
                data-testid="main-content"
                className="site-layout"
                style={{
                    transition: '0.2s ease-in-out',
                    marginLeft: headerCollapsed ? collapsedSiderWidth : siderWidthOpened
                }}>
                {children}
                <QuickSupportModal
                    isOpen={quickSupportModalOpen}
                    setIsOpen={setQuickSupportModalOpen} />
            </Layout>
        </Layout>
    );
}
